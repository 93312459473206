import { Disclosure } from '@headlessui/react'
import {
	ChevronDownIcon,
	ChevronLeftIcon,
	ChevronRightIcon,
	ChevronUpIcon,
	PencilIcon,
	XMarkIcon
} from '@heroicons/react/24/outline'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import clsx from 'clsx'
import { DateTime } from 'luxon'

import { ProfilePhotoIcon } from 'assets/icons'
import { ReactComponent as BlockUser } from 'assets/svgs/block-user.svg'
import { ReactComponent as UnblockUser } from 'assets/svgs/unblock-user.svg'
import { Button } from 'components/app/button'
import { DeletePrompt } from 'components/app/delete-prompt'
import { AppLayout } from 'components/app/layout'
import { EllipsisMenu, MenuDropdown } from 'components/app/menu'
import { Modal } from 'components/app/modal'
import { Pagination } from 'components/app/pagination'
import { Table } from 'components/app/table'
import { UserSource, mappedSources } from 'constants/constants'
import useIsMobile from 'hooks/useIsMobile'
import { CreateClient } from 'pages/clients/create'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import companyService from 'services/company-service'
import userService from 'services/user-service'
import { getAppLang, getTKey } from 'utils/language'

export const Clients = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const isMobile = useIsMobile()

	const tKey = getTKey('clients')
	const appLanguage = getAppLang()

	const [searchText, setSearchText] = useState('')
	const [showModal, setShowModal] = useState(false)
	const [showBlockModal, setShowBlockModal] = useState<
		{ action: 'block' | 'unblock'; id: string } | undefined
	>()

	const [blockedClients, setBlockedClients] = useState<string[]>([])
	const [forceRefresh, setForceRefresh] = useState(false)
	const [users, setUsers] = useState<User[]>([])
	const [isSearchVisible, setIsSearchVisible] = useState(false)
	const [isMobileFilterOpen, setIsMobileFilterOpen] = useState(false)
	const [selectedSource, setSelectedSource] = useState<string>('allSources')
	const [sorting, setSorting] = useState<{ isSorted: boolean; type: 'asc' | 'desc' }>()

	useEffect(() => {
		userService.getCompanyUsers().then(res => {
			setUsers(res.users)
			setBlockedClients(res.blocked_clients)
		})
	}, [forceRefresh])

	const { filteredUsers } = useMemo(() => {
		const { filteredUsers } = users
			.filter(user =>
				selectedSource === 'allSources'
					? true
					: selectedSource === mappedSources[user.source as UserSource]
			)
			.sort((a, b) => a.fname.localeCompare(b.fname))
			.reduce(
				(prev, curr) => {
					if (searchText) {
						const fullName = curr.fname + ' ' + curr.lname
						if (
							curr.order_num?.toString().padStart(12, '0').includes(searchText.toLowerCase()) ||
							fullName.toLowerCase().includes(searchText.toLowerCase()) ||
							curr.email.toLowerCase().includes(searchText.toLowerCase()) ||
							curr.phone.includes(searchText) ||
							DateTime.fromMillis(Number(curr.birthDate))
								.toFormat('dd.LL.yyyy')
								.includes(searchText) ||
							curr.source.toLowerCase().includes(searchText.toLowerCase())
						) {
							return { filteredUsers: [...prev.filteredUsers, curr] }
						}
					} else {
						return { filteredUsers: [...prev.filteredUsers, curr] }
					}
					return prev
				},
				{
					filteredUsers: [] as User[]
				}
			)
		return { filteredUsers }
	}, [users, searchText, selectedSource])

	const updateClientStatus = () => {
		if (showBlockModal?.id && showBlockModal?.action) {
			companyService
				.updateClient(showBlockModal.id, showBlockModal.action)
				.then(() => {
					setForceRefresh(prev => !prev)
					toast.success(
						showBlockModal.action === 'block'
							? t(tKey('toast.blockSuccess'))
							: t(tKey('toast.unblockSuccess'))
					)
					setShowBlockModal(undefined)
				})
				.catch(err => toast.error(err?.response?.data?.message ?? t(tKey('errorUpdating'))))
		}
	}

	const columns = [
		{
			header: 'Id',
			accessorKey: 'order_num',
			cell: (data: Cell) => (
				<div className="flex gap-x-3 items-center">
					<img
						src={
							data.row.original.profile_url === 'crm'
								? ProfilePhotoIcon
								: data.row.original.profile_url
						}
						className="h-6 w-6 object-cover rounded-full"
					/>
					{data.getValue()?.toString().padStart(12, '0')}
				</div>
			)
		},
		{
			header: t(tKey('tables.fullName')),
			accessorFn: (row: User) => `${row.fname} ${row.lname}`
		},
		{
			header: t(tKey('tables.email')),
			accessorKey: 'email'
		},
		{
			header: t(tKey('tables.phone')),
			accessorKey: 'phone'
		},
		{
			header: t(tKey('tables.birthday')),
			accessorKey: 'birthDate',
			cell: (date: Cell) =>
				DateTime.fromMillis(Number(date.getValue())).setLocale(appLanguage).toFormat('dd.LL.yyyy')
		},
		{
			header: t(tKey('tables.source')),
			accessorKey: 'source',
			cell: (source: Cell) => (
				<span
					className={clsx(
						'px-5 py-1 flex justify-center w-fit rounded-[20px] text-center whitespace-nowrap text-white font-semibold text-sm',
						{
							'bg-[#3577F1]': source.getValue() === UserSource.APPOINTO,
							'bg-[#61BC5F]': source.getValue() === UserSource.CRM
						}
					)}>
					{mappedSources[source.getValue() as UserSource]}
				</span>
			)
		},
		{
			id: 'action-client',
			hasNoHeading: true,
			cell: (data: Cell) => (
				<div className="flex gap-x-6 justify-end items-center">
					<svg
						onClick={() => navigate(`/client-details/${data.row.original._id}`)}
						xmlns="http://www.w3.org/2000/svg"
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						className="cursor-pointer group shrink-0">
						<path
							d="M5 19H6.09808L16.7962 8.30193L15.6981 7.20385L5 17.9019V19ZM4.80768 20C4.57884 20 4.38702 19.9226 4.2322 19.7678C4.0774 19.613 4 19.4212 4 19.1923V18.1519C4 17.9308 4.04359 17.7201 4.13078 17.5198C4.21794 17.3194 4.33397 17.1468 4.47885 17.0019L17.1808 4.28652C17.2832 4.19517 17.3963 4.12458 17.5201 4.07475C17.6439 4.02492 17.7729 4 17.9072 4C18.0416 4 18.1717 4.02115 18.2977 4.06345C18.4236 4.10577 18.5398 4.18205 18.6462 4.2923L19.7135 5.36537C19.8237 5.47179 19.899 5.58852 19.9394 5.71557C19.9798 5.84262 20 5.96968 20 6.09673C20 6.23224 19.9772 6.36189 19.9315 6.48568C19.8858 6.60946 19.8131 6.72257 19.7135 6.825L6.99808 19.5212C6.85321 19.666 6.6806 19.7821 6.48025 19.8692C6.27988 19.9564 6.06916 20 5.84808 20H4.80768ZM16.2375 7.76252L15.6981 7.20385L16.7962 8.30193L16.2375 7.76252Z"
							fill="#123258"
							className="group-hover:fill-[#123258] fill-[#7F9AB2]"
						/>
					</svg>

					{blockedClients.includes(data.row.original._id) ? (
						<svg
							fill="none"
							onClick={event => {
								event.stopPropagation()
								setShowBlockModal({ action: 'unblock', id: data.row.original._id })
							}}
							width="18"
							height="18"
							className="hover:fill-red-600 fill-red-500"
							viewBox="0 0 16 16"
							xmlns="http://www.w3.org/2000/svg">
							<g id="Layer_2" data-name="Layer 2">
								<g id="Layer_1-2" data-name="Layer 1">
									<path d="M8,8A4,4,0,1,0,4,4,4,4,0,0,0,8,8ZM8,1A3,3,0,1,1,5,4,3,3,0,0,1,8,1Zm4,7a4,4,0,1,0,4,4A4,4,0,0,0,12,8Zm3,4a3,3,0,0,1-.56,1.73L10.27,9.56A3,3,0,0,1,12,9,3,3,0,0,1,15,12ZM9,12a3,3,0,0,1,.56-1.73l4.17,4.17A3,3,0,0,1,12,15,3,3,0,0,1,9,12ZM8,15.5a.5.5,0,0,1-.5.5h-6A1.5,1.5,0,0,1,0,14.5,4.51,4.51,0,0,1,4.5,10h2a.5.5,0,0,1,0,1h-2A3.5,3.5,0,0,0,1,14.5a.5.5,0,0,0,.5.5h6A.5.5,0,0,1,8,15.5Z" />
								</g>
							</g>
						</svg>
					) : (
						<svg
							onClick={event => {
								event.stopPropagation()
								setShowBlockModal({ action: 'block', id: data.row.original._id })
							}}
							version="1.1"
							id="Layer_1"
							width="22"
							height="22"
							xmlns="http://www.w3.org/2000/svg"
							className="hover:fill-green-600 fill-green-500"
							x="0px"
							y="0px"
							viewBox="0 0 24 24"
							xmlSpace="preserve">
							<g id="Icones_Outline">
								<g id="Unblock_Black_24dp">
									<g id="noun_user-unlock_710810" transform="translate(2.000000, 2.000000)">
										<path
											id="Shape"
											d="M18.8,14.3h-3.1c0,0,0,0,0,0v-0.7c0-0.6,0.5-1.1,1.1-1.1c0.6,0,1.1,0.5,1.1,1.1h1.2c0-1.3-1-2.3-2.3-2.3
				c-1.1,0-2,0.8-2.3,1.8c-0.9-0.8-1.9-1.4-3.1-1.8c1.7-1,2.9-2.9,2.9-5c0-3.2-2.6-5.7-5.7-5.7C5.5,0.4,2.9,3,2.9,6.1
				c0,2.1,1.2,4,2.9,5C2.5,12.3,0,15.6,0,19.4h1.2c0-4.2,3.4-7.5,7.5-7.5c2.2,0,4.2,1,5.6,2.5c-0.4,0.2-0.6,0.6-0.6,1v3
				c0,0.6,0.5,1.2,1.2,1.2h4c0.6,0,1.2-0.5,1.2-1.2v-3C20,14.8,19.5,14.3,18.8,14.3z M4.2,6.1c0-2.5,2-4.5,4.5-4.5
				c2.5,0,4.5,2,4.5,4.5s-2,4.5-4.5,4.5S4.2,8.6,4.2,6.1z M18.8,18.4h-3.8v-2.9h3.8V18.4z"
										/>
									</g>
								</g>
							</g>
						</svg>
					)}
				</div>
			)
		}
	]

	if (isMobileFilterOpen) {
		return (
			<div>
				<div className="py-4 px-5 items-center grid grid-cols-3">
					<ChevronLeftIcon
						className="w-4 h-4 cursor-pointer"
						onClick={() => setIsMobileFilterOpen(false)}
					/>
					<h1 className="text-center font-domine font-bold text-sm text-primary">
						{t(tKey('titles.filters'))}
					</h1>
				</div>
				<div className="p-5 flex flex-col gap-y-5">
					<select
						name="source"
						onChange={e => setSelectedSource(e.target.value)}
						value={selectedSource}
						className="text-primary text-sm font-semibold rounded p-4 border border-[#D3E3F1] focus:border-[#06AB91] focus:ring-0">
						<option value={'allSources'}>{t('menu.labels.allSources')}</option>
						{Object.values(mappedSources).map(source => (
							<option key={source} value={source}>
								{source}
							</option>
						))}
					</select>
					<div className="self-end flex gap-x-6">
						<button
							onClick={() => setSelectedSource('allSources')}
							className="text-primary font-bold text-sm">
							{t(tKey('buttons.reset'))}
						</button>
						<Button onClick={() => setIsMobileFilterOpen(false)} className="font-bold text-sm">
							{t(tKey('buttons.apply'))}
						</Button>
					</div>
				</div>
			</div>
		)
	}

	const renderMobileUsersTable = (users: User[]) => {
		return (
			<>
				{users
					.sort((a, b) => {
						if (sorting?.type === 'asc') {
							return a.fname.localeCompare(b.fname)
						} else if (sorting?.type === 'desc') {
							return b.fname.localeCompare(a.fname)
						}
						return 0
					})
					.map((user, index) => (
						<div key={user._id} className="w-full">
							<Disclosure>
								{({ open }) => (
									<>
										<Disclosure.Button
											className={clsx(
												'flex justify-between w-full px-3 py-2 border border-[#1C1C1C1A]',
												{
													'bg-background': open,
													'border-t-0': index > 0
												}
											)}>
											<div className="flex w-full items-center justify-between">
												<div className="flex gap-x-2.5 items-center">
													<ChevronRightIcon
														className={clsx('h-4 w-4 text-primary shrink-0', {
															'rotate-90 transform': open
														})}
													/>
													<img
														src={user.profile_url === 'crm' ? ProfilePhotoIcon : user.profile_url}
														className="h-8 w-8 rounded-full object-cover"
													/>
													<div className="flex items-start flex-col gap-y-1">
														<h3 className="text-primary text-left text-sm font-semibold">
															{user.fname + ' ' + user.lname}
														</h3>
														<div className="flex gap-x-2 items-center">
															<p className="text-tertiary text-xs">
																{user.order_num?.toString().padStart(12, '0')}
															</p>
															<svg
																xmlns="http://www.w3.org/2000/svg"
																width="4"
																height="4"
																viewBox="0 0 4 4"
																fill="none"
																className="shrink-0">
																<circle cx="2" cy="2" r="2" fill="#D3E3F1" />
															</svg>
															<p
																className={clsx('text-xs whitespace-nowrap', {
																	'text-[#3577F1]': user.source === UserSource.APPOINTO,
																	'text-[#B790D8]': user.source === UserSource.CRM
																})}>
																{mappedSources[user.source as UserSource]}
															</p>
														</div>
													</div>
												</div>
												<div>
													<EllipsisMenu
														options={[
															{
																icon: PencilIcon,
																color: 'text-primary',
																action: () => navigate(`/client-details/${user._id}`)
															},
															{
																icon: blockedClients.includes(user._id) ? BlockUser : UnblockUser,
																color: blockedClients.includes(user._id)
																	? 'text-red-500 fill-red-500'
																	: 'text-green-500 fill-green-500 !w-6 !h-6',
																action: () =>
																	setShowBlockModal({
																		id: user._id,
																		action: blockedClients.includes(user._id) ? 'unblock' : 'block'
																	})
															}
														]}
													/>
												</div>
											</div>
										</Disclosure.Button>
										<Disclosure.Panel>
											<div className="flex px-6 py-2 gap-x-1.5 border-t-0 border border-[#1C1C1C1A] bg-background">
												<h5 className="text-primary font-semibold text-sm">
													{t(tKey('tables.birthday'))}:
												</h5>
												<p className="text-primary text-sm">
													{DateTime.fromMillis(Number(user.birthDate))
														.setLocale(appLanguage)
														.toFormat('dd LLL, yyyy')}
												</p>
											</div>
											<div className="flex px-6 py-2 gap-x-1.5 border-t-0 border border-[#1C1C1C1A] bg-background">
												<h5 className="text-primary font-semibold text-sm">
													{t(tKey('tables.email'))}:
												</h5>
												<p className="text-primary text-sm">{user.email}</p>
											</div>
											<div className="flex px-6 py-2 gap-x-1.5 border-t-0 border border-[#1C1C1C1A] bg-background">
												<h5 className="text-primary font-semibold text-sm">
													{t(tKey('tables.phone'))}:
												</h5>
												<p className="text-primary text-sm">{user.phone}</p>
											</div>
										</Disclosure.Panel>
									</>
								)}
							</Disclosure>
						</div>
					))}
			</>
		)
	}

	return (
		<AppLayout isHeaderVisible={!isMobile} title={t(tKey('titles.clients'))}>
			{showBlockModal && (
				<DeletePrompt
					heading={
						showBlockModal.action === 'block'
							? t(tKey('labels.blockUser'))
							: t(tKey('labels.unblockUser'))
					}
					message={
						showBlockModal.action === 'block'
							? t(tKey('labels.blockMessage'))
							: t(tKey('labels.unblockMessage'))
					}
					buttonText={
						showBlockModal?.action === 'block' ? t(tKey('labels.block')) : t(tKey('labels.unblock'))
					}
					onClose={() => setShowBlockModal(undefined)}
					onDelete={updateClientStatus}
				/>
			)}
			{showModal && (
				<Modal isFullHeight width="w-[900px]" showCrossIcon={false} noPadding>
					<div className="sticky max-md:grid max-md:grid-flow-col max-md:auto-cols-auto md:flex items-center md:justify-between top-0 z-10 bg-white max-md:p-5 md:px-8 py-4 border-b border-border">
						<ChevronLeftIcon
							onClick={() => setShowModal(false)}
							className="w-3 h-3 md:hidden cursor-pointer"
						/>
						<h1 className="text-primary text-[20px] max-md:text-center whitespace-nowrap max-md:text-sm font-domine font-bold">
							{t(tKey('create.titles.addClient'))}
						</h1>
						<div className="md:hidden" />
						<XMarkIcon
							onClick={() => setShowModal(false)}
							className="text-primary max-md:hidden bg-white h-6 w-6 cursor-pointer"
						/>
					</div>
					<CreateClient
						onCreation={() => {
							setForceRefresh(prev => !prev)
							setShowModal(false)
						}}
						onCancel={() => setShowModal(false)}
					/>
				</Modal>
			)}
			<div className="p-5 md:p-6 flex flex-col gap-y-5 md:gap-y-8">
				<div className="flex items-center justify-between md:hidden">
					<h1 className="text-primary font-domine font-bold">{t(tKey('titles.clients'))}</h1>
					<div className="flex gap-x-2.5 items-center">
						<div
							style={{ boxShadow: '0px 6px 24px 0px rgba(18, 50, 88, 0.08)' }}
							onClick={() => {
								if (isSearchVisible) {
									setSearchText('')
									return setIsSearchVisible(false)
								}
								setIsSearchVisible(true)
							}}
							className={clsx('rounded-full border border-[#D3E3F1] p-[5px] cursor-pointer', {
								'bg-[#1232584D]': isSearchVisible
							})}>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="20"
								height="20"
								viewBox="0 0 20 20"
								fill="none">
								<g clipPath="url(#clip0_2734_2383)">
									<path
										d="M12.9162 11.6672H12.2579L12.0245 11.4422C13.0245 10.2755 13.5412 8.68386 13.2579 6.99219C12.8662 4.67552 10.9329 2.82552 8.59954 2.54219C5.07454 2.10885 2.10788 5.07552 2.54121 8.60052C2.82454 10.9339 4.67455 12.8672 6.99121 13.2589C8.68288 13.5422 10.2745 13.0255 11.4412 12.0255L11.6662 12.2589V12.9172L15.2079 16.4589C15.5495 16.8005 16.1079 16.8005 16.4495 16.4589C16.7912 16.1172 16.7912 15.5589 16.4495 15.2172L12.9162 11.6672ZM7.91621 11.6672C5.84121 11.6672 4.16621 9.99219 4.16621 7.91719C4.16621 5.84219 5.84121 4.16719 7.91621 4.16719C9.99121 4.16719 11.6662 5.84219 11.6662 7.91719C11.6662 9.99219 9.99121 11.6672 7.91621 11.6672Z"
										fill="#123258"
									/>
								</g>
								<defs>
									<clipPath id="clip0_2734_2383">
										<rect width="20" height="20" fill="white" />
									</clipPath>
								</defs>
							</svg>
						</div>
						<div
							style={{ boxShadow: '0px 6px 24px 0px rgba(18, 50, 88, 0.08)' }}
							onClick={() => setIsMobileFilterOpen(true)}
							className={clsx('rounded-full border border-[#D3E3F1] p-[5px] cursor-pointer', {
								'bg-[#1232584D]': selectedSource !== 'allSources'
							})}>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="20"
								height="20"
								viewBox="0 0 20 20"
								fill="none">
								<path
									d="M4.16699 17.5V7.5M15.8337 2.5V5.83333V2.5ZM15.8337 17.5V9.16667V17.5ZM10.0003 2.5V12.5V2.5ZM10.0003 17.5V15.8333V17.5ZM4.16699 2.5V4.16667V2.5Z"
									stroke="#123258"
									strokeWidth="1.2"
									strokeLinecap="round"
								/>
								<path
									d="M14.1667 7.49968C14.1667 8.42015 14.9129 9.16634 15.8333 9.16634C16.7538 9.16634 17.5 8.42015 17.5 7.49968C17.5 6.5792 16.7538 5.83301 15.8333 5.83301C14.9129 5.83301 14.1667 6.5792 14.1667 7.49968Z"
									stroke="#123258"
									strokeWidth="1.2"
									strokeLinecap="round"
								/>
								<path
									d="M8.33366 14.1667C8.33366 15.0871 9.07985 15.8333 10.0003 15.8333C10.9208 15.8333 11.667 15.0871 11.667 14.1667C11.667 13.2462 10.9208 12.5 10.0003 12.5C9.07985 12.5 8.33366 13.2462 8.33366 14.1667Z"
									stroke="#123258"
									strokeWidth="1.2"
									strokeLinecap="round"
								/>
								<path
									d="M2.49967 5.83366C2.49967 6.75413 3.24587 7.50033 4.16634 7.50033C5.08682 7.50033 5.83301 6.75413 5.83301 5.83366C5.83301 4.91318 5.08682 4.16699 4.16634 4.16699C3.24587 4.16699 2.49967 4.91318 2.49967 5.83366Z"
									stroke="#123258"
									strokeWidth="1.2"
									strokeLinecap="round"
								/>
							</svg>
						</div>
						<div
							style={{ boxShadow: '0px 6px 24px 0px rgba(18, 50, 88, 0.08)' }}
							onClick={() => setShowModal(true)}
							className="rounded-full border border-[#D3E3F1] p-[5px] cursor-pointer">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="20"
								height="20"
								viewBox="0 0 20 20"
								fill="none">
								<path
									d="M9.37492 10.625H5.20825C5.03117 10.625 4.88273 10.5651 4.76294 10.4452C4.64315 10.3254 4.58325 10.1769 4.58325 9.99975C4.58325 9.82258 4.64315 9.67417 4.76294 9.55452C4.88273 9.43487 5.03117 9.37504 5.20825 9.37504H9.37492V5.20837C9.37492 5.03129 9.43484 4.88285 9.55467 4.76306C9.67452 4.64327 9.82302 4.58337 10.0002 4.58337C10.1773 4.58337 10.3257 4.64327 10.4454 4.76306C10.5651 4.88285 10.6249 5.03129 10.6249 5.20837V9.37504H14.7915C14.9686 9.37504 15.1171 9.43496 15.2369 9.55479C15.3566 9.67464 15.4165 9.82314 15.4165 10.0003C15.4165 10.1775 15.3566 10.3259 15.2369 10.4455C15.1171 10.5652 14.9686 10.625 14.7915 10.625H10.6249V14.7917C10.6249 14.9688 10.565 15.1172 10.4451 15.237C10.3253 15.3568 10.1768 15.4167 9.99963 15.4167C9.82246 15.4167 9.67405 15.3568 9.5544 15.237C9.43474 15.1172 9.37492 14.9688 9.37492 14.7917V10.625Z"
									fill="#123258"
								/>
							</svg>
						</div>
					</div>
				</div>
				<div className="flex max-md:hidden justify-between items-center">
					<div className="relative flex items-center justify-end">
						<input
							style={{ boxShadow: '0px 6px 24px 0px rgba(18, 50, 88, 0.08)' }}
							type="text"
							placeholder={t(tKey('placeholders.keyword'))}
							value={searchText}
							onChange={e => setSearchText(e.target.value)}
							className="px-4 py-2 w-[322px] placeholder:text-[#7F9AB2] text-primary focus:border-primary border border-border rounded-full"
						/>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="24"
							height="25"
							viewBox="0 0 24 25"
							fill="none"
							className="absolute -translate-x-3">
							<g clipPath="url(#clip0_2878_8260)">
								<path
									d="M15.4996 14.5006H14.7096L14.4296 14.2306C15.6296 12.8306 16.2496 10.9206 15.9096 8.89063C15.4396 6.11063 13.1196 3.89063 10.3196 3.55063C6.08965 3.03063 2.52965 6.59063 3.04965 10.8206C3.38965 13.6206 5.60965 15.9406 8.38965 16.4106C10.4196 16.7506 12.3296 16.1306 13.7296 14.9306L13.9996 15.2106V16.0006L18.2496 20.2506C18.6596 20.6606 19.3296 20.6606 19.7396 20.2506C20.1496 19.8406 20.1496 19.1706 19.7396 18.7606L15.4996 14.5006ZM9.49965 14.5006C7.00965 14.5006 4.99965 12.4906 4.99965 10.0006C4.99965 7.51063 7.00965 5.50063 9.49965 5.50063C11.9896 5.50063 13.9996 7.51063 13.9996 10.0006C13.9996 12.4906 11.9896 14.5006 9.49965 14.5006Z"
									fill="#123258"
								/>
							</g>
							<defs>
								<clipPath id="clip0_2878_8260">
									<rect width="24" height="24" fill="white" transform="translate(0 0.5)" />
								</clipPath>
							</defs>
						</svg>
					</div>
					<div className="flex gap-x-4 items-center">
						<MenuDropdown
							options={['allSources', ...Object.values(mappedSources)]}
							onChange={(value: string) => setSelectedSource(value)}
							value={selectedSource}
						/>
						<button
							style={{ boxShadow: '0px 6px 24px 0px rgba(18, 50, 88, 0.08)' }}
							onClick={() => setShowModal(true)}
							className="pr-4 pl-3 py-2.5 flex items-center gap-x-2 rounded bg-gradient-primary text-white text-sm font-semibold">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="20"
								height="20"
								viewBox="0 0 20 20"
								fill="none">
								<path
									d="M9.58333 10.4167H5V9.58333H9.58333V5H10.4167V9.58333H15V10.4167H10.4167V15H9.58333V10.4167Z"
									fill="white"
								/>
							</svg>
							<span className="whitespace-nowrap">{t(tKey('buttons.addClient'))}</span>
						</button>
					</div>
				</div>
				{isSearchVisible && (
					<div className="relative lg:hidden flex items-center">
						<input
							style={{ boxShadow: '0px 6px 24px 0px rgba(18, 50, 88, 0.08)' }}
							type="text"
							placeholder={t(tKey('placeholders.keyword'))}
							autoFocus
							className="rounded w-full placeholder:text-tertiary text-primary focus:outline-none border-border focus:ring-0 focus:border-secondary px-4 py-[5px]"
							onChange={event => setSearchText(event.target.value)}
						/>
						<XMarkIcon
							onClick={() => {
								setSearchText('')
								setIsSearchVisible(false)
							}}
							className="absolute h-6 w-6 right-3 stroke-primary stroke-1"
						/>
					</div>
				)}
				<div className="md:hidden">
					<div
						onClick={() => {
							if (sorting?.isSorted && sorting.type === 'desc') return setSorting(undefined)
							if (!sorting?.isSorted) return setSorting({ isSorted: true, type: 'asc' })
							if (sorting.type === 'asc') return setSorting({ isSorted: true, type: 'desc' })
						}}
						className="px-6 py-3 cursor-pointer flex items-center justify-between rounded-tl-xl rounded-tr-xl bg-primary">
						<h3 className="font-domine text-sm font-bold text-white">
							{t(tKey('tables.fullName'))}
						</h3>
						<div className="flex flex-col">
							<ChevronUpIcon
								className={clsx(
									'h-3 w-3 cursor-pointer',
									sorting?.isSorted && sorting.type === 'asc'
										? 'stroke-2 text-white'
										: 'text-gray-400'
								)}
							/>
							<ChevronDownIcon
								className={clsx(
									'h-3 w-3 cursor-pointer',
									sorting?.isSorted && sorting.type === 'desc'
										? 'stroke-2 text-white'
										: 'text-gray-400'
								)}
							/>
						</div>
					</div>
					<Pagination renderComponent={renderMobileUsersTable} items={filteredUsers} />
				</div>
				<Table
					data={filteredUsers}
					columns={columns}
					hasActionColumn
					shouldRedirect="client"
					className="max-md:hidden table-auto"
				/>
			</div>
		</AppLayout>
	)
}
