import { Disclosure } from '@headlessui/react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'

import clsx from 'clsx'

import { Bars3Icon, XMarkIcon } from '@heroicons/react/20/solid'
import {
	ActiveAppointmentIcon,
	ActiveCalendarIcon,
	ActiveClientIcon,
	ActiveDashboardIcon,
	ActiveMessageIcon,
	ActiveNewspaperIcon,
	ActiveServicesIcon,
	ActiveSettingsIcon,
	ActiveSubCompanyIcon,
	ActiveTaskManagerIcon,
	AppointmentSidebarIcon,
	AppointoWhiteIcon,
	CalendarSidebarIcon,
	ClientSidebarIcon,
	DashboardSidebarIcon,
	MessageIcon,
	NewspaperIcon,
	ServicesSidebarIcon,
	SettingsSidebarIcon,
	SubCompanyIcon,
	TaskManagerIcon
} from 'assets/icons'
import { LanguageMenu, ProfileMenu } from 'components/app/menu'
import { collection, onSnapshot, query, where } from 'firebase/firestore'
import { useAppDispatch, useAppSelector } from 'hooks'
import useIsMobile from 'hooks/useIsMobile'
import { setSidebarVisibility } from 'slices/sidebar'
import { getTKey } from 'utils/language'
import { db } from '../../firebase/config'
import { Button } from './button'

interface SidebarProps {
	children: React.ReactNode
}

export const Sidebar = ({ children }: SidebarProps) => {
	const { t, i18n } = useTranslation()
	const location = useLocation()
	const isMobile = useIsMobile()
	const navigate = useNavigate()
	const dispatch = useAppDispatch()

	const tKey = getTKey('sidebar')

	const auth = useAppSelector(state => state.auth)

	const [showSidebar, setShowSidebar] = useState(true)
	const [isHovered, setIsHovered] = useState<string>()
	const [unreadChatsCount, setUnreadChatsCount] = useState(0)

	const changeLanguage = (lng: string) => {
		i18n.changeLanguage(lng)
	}

	useEffect(() => {
		setShowSidebar(!isMobile)
	}, [isMobile])

	useEffect(() => {
		if (auth._id) {
			const q = query(collection(db, 'chats'), where(`unreadMessages.${auth._id}`, '==', true))
			const unsubscribe = onSnapshot(q, querySnapshot => {
				setUnreadChatsCount(querySnapshot.size)
			})
			return () => unsubscribe()
		}
	}, [auth._id])

	const navigation = [
		{
			name: t(tKey('navigations.calendar')),
			href: '/calendar',
			icon: CalendarSidebarIcon,
			activeIcon: ActiveCalendarIcon,
			current: true
		},
		{
			name: t(tKey('navigations.chats')),
			href: '/chats',
			icon: MessageIcon,
			activeIcon: ActiveMessageIcon,
			current: true,
			unreadCount: unreadChatsCount
		},
		{
			name: t(tKey('navigations.appointments')),
			href: '/appointments',
			icon: AppointmentSidebarIcon,
			activeIcon: ActiveAppointmentIcon,
			current: true
		},
		{
			name: t(tKey('navigations.events')),
			href: '/events',
			icon: NewspaperIcon,
			activeIcon: ActiveNewspaperIcon,
			current: true
		},
		{
			name: t(tKey('navigations.clients')),
			href: '/clients',
			icon: ClientSidebarIcon,
			activeIcon: ActiveClientIcon,
			current: true
		},
		{
			name: t(tKey('navigations.taskManager')),
			href: '/task-manager',
			icon: TaskManagerIcon,
			activeIcon: ActiveTaskManagerIcon,
			current: true
		}
	]

	const adminNavigations = [
		{
			name: t(tKey('navigations.dashboard')),
			href: '/',
			icon: DashboardSidebarIcon,
			activeIcon: ActiveDashboardIcon,
			current: true
		},
		{
			name: t(tKey('navigations.calendar')),
			href: '/calendar',
			icon: CalendarSidebarIcon,
			activeIcon: ActiveCalendarIcon,
			current: true
		},
		{
			name: t(tKey('navigations.chats')),
			href: '/chats',
			icon: MessageIcon,
			activeIcon: ActiveMessageIcon,
			current: true,
			unreadCount: unreadChatsCount
		},
		{
			name: t(tKey('navigations.appointments')),
			href: '/appointments',
			icon: AppointmentSidebarIcon,
			activeIcon: ActiveAppointmentIcon,
			current: true
		},
		{
			name: t(tKey('navigations.events')),
			href: '/events',
			icon: NewspaperIcon,
			activeIcon: ActiveNewspaperIcon,
			current: true
		},
		{
			name: t(tKey('navigations.clients')),
			href: '/clients',
			icon: ClientSidebarIcon,
			activeIcon: ActiveClientIcon,
			current: true
		},
		{
			name: t(tKey('navigations.taskManager')),
			href: '/task-manager',
			icon: TaskManagerIcon,
			activeIcon: ActiveTaskManagerIcon,
			current: true
		},
		{
			name: t(tKey('navigations.settings')),
			icon: SettingsSidebarIcon,
			activeIcon: ActiveSettingsIcon,
			href: '/settings',
			current: false,
			children: [
				{ name: t(tKey('navigations.company')), href: '/settings/company' },
				{ name: t(tKey('navigations.employee')), href: '/settings/employees' },
				{ name: t(tKey('navigations.licenses')), href: '/settings/licenses' },
				{ name: t(tKey('navigations.faqs')), href: '/settings/faqs' }
			]
		},
		{
			name: t(tKey('navigations.medical')),
			icon: ServicesSidebarIcon,
			activeIcon: ActiveServicesIcon,
			href: '/tools',
			current: false,
			children: [
				{ name: t(tKey('navigations.categories')), href: '/tools/categories' },
				{ name: t(tKey('navigations.services')), href: '/tools/services' },
				{ name: t(tKey('navigations.questionnaire')), href: '/tools/questionnaire' }
			]
		}
	]

	const superAdminNavigations = [
		{
			name: t(tKey('navigations.dashboard')),
			href: '/',
			icon: DashboardSidebarIcon,
			activeIcon: ActiveDashboardIcon,
			current: true
		},
		{
			name: t(tKey('navigations.calendar')),
			href: '/calendar',
			icon: CalendarSidebarIcon,
			activeIcon: ActiveCalendarIcon,
			current: true
		},
		{
			name: t(tKey('navigations.subCompanies')),
			href: '/subcompanies',
			icon: SubCompanyIcon,
			children: [
				{ name: t(tKey('navigations.company')), href: '/subcompanies/company' },
				{ name: t(tKey('navigations.admins')), href: '/subcompanies/admins' }
			],
			activeIcon: ActiveSubCompanyIcon,
			current: true
		},
		{
			name: t(tKey('navigations.chats')),
			href: '/chats',
			icon: MessageIcon,
			activeIcon: ActiveMessageIcon,
			current: true,
			unreadCount: unreadChatsCount
		},
		{
			name: t(tKey('navigations.appointments')),
			href: '/appointments',
			icon: AppointmentSidebarIcon,
			activeIcon: ActiveAppointmentIcon,
			current: true
		},
		{
			name: t(tKey('navigations.events')),
			href: '/events',
			icon: NewspaperIcon,
			activeIcon: ActiveNewspaperIcon,
			current: true
		},
		{
			name: t(tKey('navigations.clients')),
			href: '/clients',
			icon: ClientSidebarIcon,
			activeIcon: ActiveClientIcon,
			current: true
		},
		{
			name: t(tKey('navigations.taskManager')),
			href: '/task-manager',
			icon: TaskManagerIcon,
			activeIcon: ActiveTaskManagerIcon,
			current: true
		},
		{
			name: t(tKey('navigations.settings')),
			icon: SettingsSidebarIcon,
			activeIcon: ActiveSettingsIcon,
			href: '/settings',
			current: false,
			children: [
				{ name: t(tKey('navigations.company')), href: '/settings/company' },
				{ name: t(tKey('navigations.employee')), href: '/settings/employees' },
				{ name: t(tKey('navigations.licenses')), href: '/settings/licenses' },
				{ name: t(tKey('navigations.faqs')), href: '/settings/faqs' }
			]
		},
		{
			name: t(tKey('navigations.medical')),
			icon: ServicesSidebarIcon,
			activeIcon: ActiveServicesIcon,
			href: '/tools',
			current: false,
			children: [
				{ name: t(tKey('navigations.categories')), href: '/tools/categories' },
				{ name: t(tKey('navigations.services')), href: '/tools/services' },
				{ name: t(tKey('navigations.questionnaire')), href: '/tools/questionnaire' }
			]
		}
	]

	return (
		<div>
			{showSidebar && (
				<div className="fixed inset-y-0 flex md:w-64 flex-col z-[9999] max-md:w-full">
					<div className="flex min-h-screen md:min-h-0 flex-1 flex-col border-r border-[#1232581A] bg-[#001E00]">
						<div className="flex flex-1 flex-col overflow-y-auto pt-5 pb-4">
							<div className="flex justify-between mt-4 flex-shrink-0 items-center px-4 md:mb-[22px]">
								<img
									className="rounded-md w-[131px] mx-auto"
									src={AppointoWhiteIcon}
									alt="pro.appointo.ch"
								/>
								<XMarkIcon
									onClick={() => setShowSidebar(false)}
									className="h-5 w-5 text-white cursor-pointer md:hidden"
									aria-hidden="true"
								/>
							</div>
							<div
								onClick={() => {
									dispatch(setSidebarVisibility(false))
									setShowSidebar(false)
								}}
								className="bg-white cursor-pointer border border-[#1232581A] border-r-0 rounded-l-xl py-1 px-1.5 ml-auto max-md:hidden">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="16"
									height="16"
									viewBox="0 0 16 16"
									fill="none">
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M10.3403 12.3536C10.5532 12.1583 10.5532 11.8417 10.3403 11.6464L6.75 8.35355C6.5371 8.15829 6.5371 7.84171 6.75 7.64645L10.3403 4.35355C10.5532 4.15829 10.5532 3.84171 10.3403 3.64645C10.1274 3.45118 9.78225 3.45118 9.56935 3.64645L5.97902 6.93934C5.34033 7.52513 5.34032 8.47487 5.97902 9.06066L9.56935 12.3536C9.78225 12.5488 10.1274 12.5488 10.3403 12.3536Z"
										fill="#123258"
									/>
								</svg>
							</div>
							<div className="md:pt-1 pt-8">
								<nav className="flex-1 space-y-1 bg-[#001E00] md:px-4" aria-label="Sidebar">
									{(auth.roles.includes('ROLE_SUPERADMIN')
										? superAdminNavigations
										: auth.is_adminUser === true
											? adminNavigations
											: navigation
									).map(item =>
										!('children' in item && item.children) ? (
											<div key={item.name}>
												<NavLink
													to={item.href}
													onMouseEnter={() => setIsHovered(item.name)}
													onMouseLeave={() => setIsHovered(undefined)}
													className={({ isActive }) =>
														clsx(
															'group flex text-white hover:bg-[#334B33] hover:text-white items-center pl-10 pr-2 py-2 text-sm rounded-xl',
															isActive ? 'bg-[#334B33] text-white' : 'bg-primary'
														)
													}>
													{({ isActive }) => (
														<div className="flex items-center gap-x-2">
															<img
																src={
																	isActive || isHovered === item.name ? item.activeIcon : item.icon
																}
																className={clsx('flex-shrink-0')}
																aria-hidden="true"
															/>
															{item.name}
															{item.unreadCount && item.unreadCount > 0 ? (
																<span className="ml-auto bg-red-500 text-white text-xs font-semibold px-2 py-0.5 rounded-full">
																	{item.unreadCount}
																</span>
															) : (
																''
															)}
														</div>
													)}
												</NavLink>
											</div>
										) : (
											<Disclosure
												as="div"
												key={item.name}
												className="space-y-1"
												defaultOpen={location.pathname.includes(item.href)}>
												<Disclosure.Button
													onMouseEnter={() => setIsHovered(item.name)}
													onMouseLeave={() => setIsHovered(undefined)}
													className={({ open }) =>
														clsx(
															'group flex w-full text-white hover:bg-[#334B33] hover:text-white items-center rounded-xl py-2 pl-4 pr-1 text-left text-sm focus:outline-none',
															open ? 'bg-[#334B33] text-white' : 'bg-primary'
														)
													}>
													{({ open }) => (
														<div className="flex gap-x-2 items-center">
															<div className="flex gap-x-1 items-center">
																<svg
																	xmlns="http://www.w3.org/2000/svg"
																	width="16"
																	height="16"
																	viewBox="0 0 16 16"
																	fill="none"
																	className={clsx(open ? 'rotate-90' : 'rotate-0')}>
																	<path
																		fillRule="evenodd"
																		clipRule="evenodd"
																		d="M5.65967 12.3536C5.44678 12.1583 5.44678 11.8417 5.65967 11.6464L9.25 8.35355C9.4629 8.15829 9.4629 7.84171 9.25 7.64645L5.65968 4.35355C5.44678 4.15829 5.44678 3.84171 5.65968 3.64645C5.87257 3.45118 6.21775 3.45118 6.43065 3.64645L10.021 6.93934C10.6597 7.52513 10.6597 8.47487 10.021 9.06066L6.43065 12.3536C6.21775 12.5488 5.87257 12.5488 5.65967 12.3536Z"
																		fill={open ? '#61BC5F' : '#7F9AB2'}
																		className="hover:fill-primary-light"
																	/>
																</svg>
															</div>
															<img
																src={open || isHovered === item.name ? item.activeIcon : item.icon}
																className={clsx('flex-shrink-0')}
																aria-hidden="true"
															/>

															<span className="flex-1">{item.name}</span>
														</div>
													)}
												</Disclosure.Button>
												<Disclosure.Panel className="space-y-1">
													{Array.isArray(item.children) &&
														item.children.map(subItem => (
															<NavLink
																key={subItem.name}
																to={subItem.href}
																className={({ isActive }) =>
																	clsx(
																		'group flex w-full items-center rounded-xl py-2 pl-[69px] pr-2 text-sm text-white hover:bg-[#334B33] hover:text-white',
																		isActive ? 'bg-[#334B33] text-white' : 'bg-primary'
																	)
																}>
																{subItem.name}
															</NavLink>
														))}
												</Disclosure.Panel>
											</Disclosure>
										)
									)}
								</nav>
							</div>
						</div>
					</div>
				</div>
			)}

			<main>
				<div className={clsx('relative', showSidebar && 'md:ml-64')}>
					<div className="flex md:hidden px-5 py-3.5 items-center justify-between">
						<Bars3Icon
							onClick={() => setShowSidebar(true)}
							className="h-5 w-5 cursor-pointer"
							aria-hidden="true"
						/>
						<div className="flex gap-x-3 items-center">
							<Button
								className="rounded-md font-domine font-semibold text-xs px-2 py-2"
								onClick={() => navigate('/support')}>
								{t('headers.buttons.getSupport')}
							</Button>
							<LanguageMenu
								value={i18n.language || window.localStorage.i18nextLng}
								onChange={changeLanguage}
								options={['en', 'de']}
							/>
							<ProfileMenu />
						</div>
					</div>
					<div
						onClick={() => {
							setShowSidebar(true)
							dispatch(setSidebarVisibility(true))
						}}
						className={clsx(
							'absolute top-[72px] bg-white cursor-pointer border border-[#1232581A] border-l-0 rounded-r-xl py-1 px-1.5 ml-auto max-md:hidden',
							{ hidden: showSidebar }
						)}>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							className="rotate-180"
							width="16"
							height="16"
							viewBox="0 0 16 16"
							fill="none">
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M10.3403 12.3536C10.5532 12.1583 10.5532 11.8417 10.3403 11.6464L6.75 8.35355C6.5371 8.15829 6.5371 7.84171 6.75 7.64645L10.3403 4.35355C10.5532 4.15829 10.5532 3.84171 10.3403 3.64645C10.1274 3.45118 9.78225 3.45118 9.56935 3.64645L5.97902 6.93934C5.34033 7.52513 5.34032 8.47487 5.97902 9.06066L9.56935 12.3536C9.78225 12.5488 10.1274 12.5488 10.3403 12.3536Z"
								fill="#123258"
							/>
						</svg>
					</div>
					{children}
				</div>
			</main>
		</div>
	)
}
