import { Combobox, Transition } from '@headlessui/react'
import { Dispatch, Fragment, SetStateAction, useEffect, useMemo, useState } from 'react'
import {
	FieldErrors,
	UseFormGetValues,
	UseFormRegister,
	UseFormSetValue,
	UseFormWatch,
	useForm
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { yupResolver } from '@hookform/resolvers/yup'
import clsx from 'clsx'
import cond from 'cond-construct'
import { DateTime } from 'luxon'
import * as yup from 'yup'

import { PlusCircleIcon } from '@heroicons/react/24/outline'
import { ProfilePhotoIcon } from 'assets/icons'
import { Spinner } from 'components/animations/spinner'
import { Button } from 'components/app/button'
import { TimePicker } from 'components/app/time-picker'
import { Input } from 'components/inputs/input'
import { Select } from 'components/inputs/select'
import { AppointmentStatus } from 'constants/constants'
import { useAppSelector } from 'hooks'
import appointmentService from 'services/appointment-service'
import categoryService from 'services/category-service'
import companyService from 'services/company-service'
import medicalService from 'services/medical-service'
import userService from 'services/user-service'
import { socket } from 'sockets/socket-context'
import { appendDateTime, getTimeRange } from 'utils/date'
import { getAppLang, getTKey } from 'utils/language'

enum FormSteps {
	APPOINTMENTINFO,
	APPOINTMENTQUESTIONS,
	CONFIRMAPPOINTMENT
}

interface CreateAppointmentProps {
	showInfo?: boolean
	className?: string
	createdClient?: string
	onAddClient?: () => void
	onConfirm: () => void
	onCancel: () => void
}

export const CreateAppointment = ({
	showInfo = true,
	createdClient,
	onAddClient,
	className,
	onConfirm,
	onCancel
}: CreateAppointmentProps) => {
	const { t } = useTranslation()

	const tKey = getTKey('appointments.create')

	const auth = useAppSelector(state => state.auth)

	const [steps, setSteps] = useState(FormSteps.APPOINTMENTINFO)
	const [isLoading, setIsLoading] = useState(false)
	const [company, setCompany] = useState<Company>()
	const [companyCategories, setCompanyCategories] = useState<Category[]>([])
	const [serviceByCategory, setServiceByCategory] = useState<Service[]>([])
	const [questions, setQuestions] = useState<AppointmentQuestion[]>([])
	const [additionalInfo, setAdditionalInfo] = useState<string>('')
	const [answeredQuestions, setAnsweredQuestions] = useState<{ [index: string]: any }>({})

	const schema = yup.object<AppointmentForm>().shape({
		id_client: yup.string().when('$step', {
			is: FormSteps.APPOINTMENTINFO,
			then: schema => schema.required(t(tKey('errors.client')))
		}),
		id_category: yup.string().when('$step', {
			is: FormSteps.APPOINTMENTINFO,
			then: schema => schema.required(t(tKey('errors.category')))
		}),
		id_service: yup.string().when('$step', {
			is: FormSteps.APPOINTMENTINFO,
			then: schema =>
				schema.test('id_service-test', t(tKey('errors.service')), function (value) {
					if (this.parent.id_category && !value) return false
					return true
				})
		}),
		id_employee: yup.string().when('$step', {
			is: FormSteps.APPOINTMENTINFO,
			then: schema =>
				schema.test('id_employee-test', t(tKey('errors.employee')), function (value) {
					const selectedService = this.parent.id_service
					if (
						(serviceByCategory.find(ser => ser._id === selectedService)?.id_employees
							.length as number) > 0 &&
						!value
					)
						return false
					return true
				})
		}),
		timeslot: yup.string().when('$step', {
			is: FormSteps.APPOINTMENTINFO,
			then: schema => schema.required(t(tKey('errors.timeSlot')))
		})
	})

	const {
		register,
		watch,
		setValue,
		getValues,
		handleSubmit,
		formState: { errors }
	} = useForm<AppointmentForm>({
		resolver: yupResolver(schema as any),
		context: { step: steps },
		mode: 'all'
	})

	const selectedCategory = watch('id_category')
	const selectedService = watch?.('id_service')

	useEffect(() => {
		companyService.getCompanyById(auth.companyId).then(res => setCompany(res))
		if (auth.is_adminUser === true) {
			categoryService
				.getCompanyCategories()
				.then(res => setCompanyCategories(res.filter(r => r.is_active === 'aktiv')))
		} else {
			categoryService
				.getCompanyCategoriesForEmployee()
				.then(res => setCompanyCategories(res.filter(r => r.is_active === 'aktiv')))
		}
	}, [auth])

	useEffect(() => {
		if (selectedCategory) {
			medicalService
				.getCompanyServiceByCategory(selectedCategory)
				.then(res => setServiceByCategory(res.filter(r => r.is_active === 'aktiv')))
		}
		setValue('id_service', '')
	}, [selectedCategory])

	useEffect(() => {
		if (selectedService) {
			setValue?.('timeslot' as any, '')
			appointmentService
				.getAppointmentQuestionsByService(selectedService, 'questions')
				.then(res => setQuestions(res))
		}
	}, [selectedService])

	const nextStep = handleSubmit(data => {
		setSteps(prev => prev + 1)
	})

	const prevStep = () => {
		steps === FormSteps.APPOINTMENTINFO ? onCancel() : setSteps(prev => prev - 1)
	}

	const onSubmit = handleSubmit((data: any) => {
		setIsLoading(true)
		const payload: AppointmentForm = {
			booking_as: 'myself',
			company_name: company?.company_name as string,
			from: appendDateTime(data.appointmentDate, data.timeslot),
			to: appendDateTime(
				data.appointmentDate,
				DateTime.fromFormat(data.timeslot, 'HH:mm')
					.plus({
						minutes: Number(
							serviceByCategory.find(ser => ser._id === data.id_service)?.duration ?? 0
						)
					})
					.toFormat('HH:mm')
			),
			id_category: data.id_category,
			id_client: data.id_client,
			id_employee: data.id_employee ? data.id_employee : undefined,
			id_company: auth.companyId,
			questions: answeredQuestions,
			additonal_information: additionalInfo,
			id_service: data.id_service,
			status: AppointmentStatus.PENDENT,
			created: DateTime.now().toMillis(),
			service: serviceByCategory.find(service => service._id === data.id_service) as Service,
			service_name: serviceByCategory.find(service => service._id === data.id_service)
				?.name as string
		}

		appointmentService
			.createAppointment(payload)
			.then(res => {
				toast.success(t(tKey('toast.appointmentSuccess')))
				socket.emit('Appointment created', {
					appointment: res.data
				})
				onConfirm()
			})
			.catch(err => toast.error(err?.response?.data?.message ?? t(tKey('toast.appointmentError'))))
			.finally(() => setIsLoading(false))
	})

	const renderComponent = cond([
		[
			steps === FormSteps.APPOINTMENTINFO,
			() => (
				<AppointmentInfoForm
					{...{
						errors,
						register,
						watch,
						showInfo,
						createdClient,
						setValue,
						onAddClient,
						getValues,
						selectedCategory,
						selectedService,
						categories: companyCategories,
						services: serviceByCategory
					}}
				/>
			)
		],
		[
			steps === FormSteps.APPOINTMENTQUESTIONS,
			() => (
				<AppointmentQuestionForm
					{...{
						errors,
						register,
						watch,
						showInfo,
						createdClient,
						answeredQuestions,
						setAnsweredQuestions,
						categories: companyCategories,
						services: serviceByCategory,
						questions: questions,
						additionalInfo,
						setAdditionalInfo
					}}
				/>
			)
		]
	])

	return (
		<form
			className={clsx('max-md:px-5 md:px-8 py-6', className)}
			onSubmit={event => {
				if (steps === FormSteps.APPOINTMENTQUESTIONS) {
					onSubmit(event)
				} else {
					nextStep(event)
				}
			}}>
			<>{renderComponent}</>
			<div className="flex justify-end gap-x-6 py-6">
				<button className="max-md:text-sm" type="button" onClick={prevStep}>
					{steps === FormSteps.APPOINTMENTINFO
						? t(tKey('buttons.cancel'))
						: t(tKey('buttons.back'))}
				</button>
				<Button
					disabled={isLoading}
					className="px-8 py-3 max-md:text-sm max-md:py-[9px]"
					type="submit">
					{isLoading ? (
						<div className="flex items-center justify-center gap-x-5">
							<Spinner />
							<span className="animate-pulse whitespace-nowrap">
								{t(tKey('buttons.pleaseWait'))}
							</span>
						</div>
					) : steps === FormSteps.APPOINTMENTQUESTIONS ? (
						t(tKey('buttons.makeAppointment'))
					) : (
						t(tKey('buttons.next'))
					)}
				</Button>
			</div>
		</form>
	)
}

interface FormProps {
	register: UseFormRegister<AppointmentForm>
	errors: FieldErrors<any>
	setValue?: UseFormSetValue<AppointmentForm>
	getValues?: UseFormGetValues<AppointmentForm>
	watch?: UseFormWatch<any>
}

interface AppointmentFormProps extends FormProps {
	categories: Category[]
	services: Service[]
	onAddClient?: () => void
	createdClient?: string
	selectedCategory: string
	selectedService: string
	showInfo: boolean
}

const AppointmentInfoForm = ({
	register,
	errors,
	watch,
	onAddClient,
	createdClient,
	setValue,
	showInfo = true,
	getValues,
	categories,
	selectedCategory,
	selectedService,
	services
}: AppointmentFormProps) => {
	const { t } = useTranslation()

	const tKey = getTKey('appointments.create')

	const auth = useAppSelector(state => state.auth)

	const appointmentDate = watch?.('appointmentDate')
	const selectedTimeSlot = watch?.('timeslot')
	const employeeId = watch?.('id_employee')

	const [serviceSchedule, setServiceSchedule] = useState<Schedule[]>()
	const [query, setQuery] = useState('')
	const [users, setUsers] = useState<User[]>([])
	const [forceRefresh, setForceRefresh] = useState(false)

	useEffect(() => {
		userService.getCompanyUsers().then(res => {
			setUsers(res.users)
			if (createdClient) {
				setValue?.('id_client', createdClient, { shouldValidate: true })
			}
		})
	}, [forceRefresh])

	const filteredOptions = useMemo(() => {
		if (users) {
			return users.filter(user => {
				const name = user.fname + ' ' + user.lname
				return (
					query === '' ||
					name
						.toLowerCase()
						.replace(/\s+/g, '')
						.includes(query.toLowerCase().replace(/\s+/g, '')) ||
					DateTime.fromMillis(Number(user.birthDate))
						.toFormat('dd.LL.yyyy')
						.toLowerCase()
						.includes(query.toLowerCase()) ||
					user.city
						?.toLowerCase()
						.replace(/\s+/g, '')
						.includes(query.toLowerCase().replace(/\s+/g, '')) ||
					user.zip
						?.toLowerCase()
						.replace(/\s+/g, '')
						.includes(query.toLowerCase().replace(/\s+/g, ''))
				)
			})
		} else {
			return users
		}
	}, [users, query])

	useEffect(() => {
		if (selectedService) {
			medicalService.getServiceSchedule(selectedService).then(res => setServiceSchedule(res))
		}
	}, [selectedService])

	const timeSlots = useMemo(() => {
		const mergedTime: Record<number, string[]> = {}
		if (serviceSchedule && serviceSchedule.length > 0) {
			for (const schedule of serviceSchedule) {
				if (schedule.type === 'Closed') continue
				const timeRange = getTimeRange(
					DateTime.fromObject(schedule.start).toMillis(),
					DateTime.fromObject(schedule.end).toMillis(),
					DateTime.fromObject(schedule.pause_start).toMillis(),
					DateTime.fromObject(schedule.pause_end).toMillis(),
					false,
					services.find(service => service._id === selectedService)?.duration as string,
					schedule.day,
					Number(services.find(service => service._id === selectedService)?.min_booking) / 60
				)
				if (timeRange) {
					Object.entries(timeRange).forEach(([key, value]) => {
						const day = parseInt(key)
						if (!mergedTime[day]) {
							mergedTime[day] = []
						}
						mergedTime[day].push(...value)
					})
				}
			}
		}
		return mergedTime
	}, [serviceSchedule])

	const todayTimeSlots = useMemo(() => {
		if (serviceSchedule && serviceSchedule.length > 0) {
			for (const schedule of serviceSchedule) {
				if (schedule.type === 'Closed') return
				return getTimeRange(
					DateTime.fromObject(schedule.start).toMillis(),
					DateTime.fromObject(schedule.end).toMillis(),
					DateTime.fromObject(schedule.pause_start).toMillis(),
					DateTime.fromObject(schedule.pause_end).toMillis(),
					true,
					services.find(service => service._id === selectedService)?.duration as string,
					DateTime.now().weekday === 7 ? 0 : DateTime.now().weekday,
					Number(services.find(service => service._id === selectedService)?.min_booking) / 60
				)
			}
		}
	}, [serviceSchedule])

	return (
		<div className="flex min-h-[70vh] flex-col gap-y-[18px]">
			<div className="flex justify-between items-start">
				<h3 className="text-primary max-md:text-xs">{t(tKey('titles.requiredDetails'))}</h3>
			</div>

			<Combobox
				value={getValues?.('id_client')}
				onChange={(value: string) => setValue?.('id_client', value, { shouldValidate: true })}>
				<div className="relative mt-1">
					<div className="relative cursor-default w-full overflow-hidden bg-white text-left border-none focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
						<Combobox.Input
							placeholder={t(tKey('placeholders.client'))}
							className="border border-border pr-7 w-full py-2 md:py-3 rounded focus:border-secondary text-sm leading-5 text-gray-900 focus:ring-0"
							displayValue={option =>
								option
									? users.find(user => user._id === getValues?.('id_client'))?.fname +
										' ' +
										users.find(user => user._id === getValues?.('id_client'))?.lname
									: ''
							}
							onChange={event => setQuery(event.target.value)}
						/>
						{errors.id_client && (
							<p className="text-red-500 mt-1 text-xs">{errors.id_client.message as string}</p>
						)}
						<Combobox.Button
							className={clsx('absolute inset-y-0 flex items-center right-0 pr-2', {
								'-translate-y-2': errors.id_client
							})}>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="20"
								height="20"
								viewBox="0 0 20 20"
								fill="none">
								<path
									d="M9.99988 12.2178C9.89945 12.2178 9.80597 12.2018 9.71944 12.1697C9.6329 12.1377 9.55064 12.0826 9.47265 12.0047L5.72746 8.25947C5.61207 8.14409 5.55305 7.99906 5.55038 7.82438C5.5477 7.6497 5.60673 7.502 5.72746 7.38128C5.84819 7.26056 5.99455 7.2002 6.16655 7.2002C6.33855 7.2002 6.48491 7.26056 6.60563 7.38128L9.99988 10.7755L13.3941 7.38128C13.5095 7.2659 13.6545 7.20687 13.8292 7.20419C14.0039 7.20153 14.1516 7.26056 14.2723 7.38128C14.393 7.502 14.4534 7.64837 14.4534 7.82038C14.4534 7.99238 14.393 8.13874 14.2723 8.25947L10.5271 12.0047C10.4491 12.0826 10.3669 12.1377 10.2803 12.1697C10.1938 12.2018 10.1003 12.2178 9.99988 12.2178Z"
									fill="#123258"
								/>
							</svg>
						</Combobox.Button>
					</div>
					<Transition
						as={Fragment}
						leave="transition ease-in duration-100"
						leaveFrom="opacity-100"
						leaveTo="opacity-0">
						<Combobox.Options className="absolute mt-1 z-50 max-h-60 min-w-max w-full overflow-auto rounded-md bg-white pt-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
							{Array.isArray(users) &&
								filteredOptions.map((option, index) => (
									<Combobox.Option
										key={index}
										className={({ active }) =>
											`relative cursor-default select-none py-0.5 ${
												active ? 'bg-[#13BAB40F] text-white' : 'text-gray-900'
											}`
										}
										value={option._id}>
										{({ selected, active }) => (
											<span className="flex gap-x-3 px-4 md:px-6 py-1.5 items-center">
												<img
													src={option.profile_url === 'crm' ? ProfilePhotoIcon : option.profile_url}
													className="h-6 w-6 rounded-full"
												/>
												<span className="flex-col group items-start text-primary font-semibold w-full overflow-hidden text-ellipsis text-xs md:text-sm whitespace-nowrap grow hover:bg-[#13BAB40F] flex text-left rounded-md">
													{option.fname + ' ' + option.lname}

													<span className="text-tertiary text-xs">
														{DateTime.fromMillis(Number(option.birthDate)).toFormat('dd.LL.yyyy')}
														{option.city && option.zip ? ` - ${option.city} - ${option.zip}` : ''}
													</span>
												</span>
											</span>
										)}
									</Combobox.Option>
								))}

							{!showInfo && (
								<div
									onClick={onAddClient}
									className="flex cursor-pointer sticky bottom-0 w-full bg-white items-center px-3 justify-end gap-2 py-3.5">
									<PlusCircleIcon className="h-3.5 w-3.5 text-primary-light" />
									<p className="text-sm text-primary-light">
										{t('calendar.createEvent.labels.addNewClient')}
									</p>
								</div>
							)}
						</Combobox.Options>
					</Transition>
				</div>
			</Combobox>

			<Select name="id_category" register={register} errors={errors}>
				<option value="">{t(tKey('placeholders.category'))}</option>
				{categories.map(category => (
					<option key={category._id} value={category._id}>
						{category.category_name}
					</option>
				))}
			</Select>
			{services.length > 0 && selectedCategory && (
				<Select name="id_service" register={register} errors={errors}>
					<option value="">{t(tKey('placeholders.service'))}</option>
					{services.map(service => (
						<option key={service._id} value={service._id}>
							{service.name}
						</option>
					))}
				</Select>
			)}
			{selectedService &&
				(services.find(ser => ser._id === selectedService)?.id_employees.length as number) > 0 && (
					<Select name="id_employee" register={register} errors={errors}>
						<option value="">{t(tKey('placeholders.employee'))}</option>
						{services
							.find(ser => ser._id === selectedService)
							?.id_employees.map(employee => (
								<option key={employee._id} value={employee._id}>
									{employee.fname + ' ' + employee.lname}
								</option>
							))}
					</Select>
				)}
			<div className="relative z-20 flex items-center">
				{selectedService && (
					<TimePicker
						companyId={auth.companyId}
						duration={services.find(service => service._id === selectedService)?.duration as string}
						timeSlots={timeSlots as { [day: number]: string[] }}
						todayTimeSlots={todayTimeSlots as { [day: number]: string[] }}
						setValue={setValue}
						selectedTimeSlot={selectedTimeSlot as string}
						selectedDate={appointmentDate as Date}
						employeeId={employeeId}
					/>
				)}
			</div>
			{selectedService && errors?.timeslot && (
				<p className="text-xs mt-1 text-red-600">{errors.timeslot.message as string}</p>
			)}
		</div>
	)
}

interface AppointmentQuestionFormProps extends FormProps {
	categories: Category[]
	services: Service[]
	additionalInfo: string
	setAdditionalInfo: (value: string) => void
	questions: AppointmentQuestion[]
	answeredQuestions: { [index: string]: any }
	setAnsweredQuestions: Dispatch<SetStateAction<{ questions: ObjectConstructor }>>
}

const AppointmentQuestionForm = ({
	watch,
	categories,
	services,
	answeredQuestions,
	setAnsweredQuestions,
	additionalInfo,
	setAdditionalInfo,
	showInfo = true,
	questions
}: AppointmentQuestionFormProps & { showInfo?: boolean }) => {
	const { t } = useTranslation()

	const appLanguage = getAppLang()
	const tKey = getTKey('appointments.create')

	const clientId = watch?.('id_client')
	const categoryId = watch?.('id_category')
	const serviceId = watch?.('id_service')
	const appointmentDate = watch?.('appointmentDate')
	const timeSlot = watch?.('timeslot')

	const [users, setUsers] = useState<User[]>([])

	useEffect(() => {
		userService.getCompanyUsers().then(res => setUsers(res.users))
	}, [])

	const selectedClient = users.find(user => user._id === clientId)
	const selectedCategory = categories.find(category => category._id === categoryId)
	const selectedService = services.find(service => service._id === serviceId)

	const handleChange = (event: React.ChangeEvent<any>, question: string, group?: string) => {
		setAnsweredQuestions((prev: any) => ({
			...prev,
			[question
				.replace('?', '')
				.replace(/\s*\([^)]*\)/, '')
				.trim()]: { value: event.target.value, group: group }
		}))
	}

	return (
		<div className="flex flex-col gap-y-3.5 md:gap-y-[18px]">
			{showInfo && (
				<div className="flex max-md:flex-col px-6 py-3 max-md:gap-y-2 justify-around bg-[#D3E3F14D]">
					<div className="flex items-center gap-x-5">
						{selectedClient?.profile_url === 'crm' ? (
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="36"
								height="35"
								viewBox="0 0 36 35"
								fill="none">
								<g clipPath="url(#clip0_3123_4045)">
									<circle cx="18" cy="10.4412" r="6" fill="#7F9AB2" fillOpacity="0.5" />
									<ellipse
										cx="18"
										cy="30.9412"
										rx="14"
										ry="12.5"
										fill="#7F9AB2"
										fillOpacity="0.5"
									/>
								</g>
								<defs>
									<clipPath id="clip0_3123_4045">
										<rect width="36" height="36" fill="white" transform="translate(0 0.441162)" />
									</clipPath>
								</defs>
							</svg>
						) : (
							<img
								src={selectedClient?.profile_url}
								className="md:h-9 md:w-9 h-5 w-5 rounded-full object-cover"
							/>
						)}
						<p className="text-primary max-md:text-sm">
							{selectedClient?.fname + ' ' + selectedClient?.lname}
						</p>
					</div>
					<div className="flex items-center gap-x-3">
						<p className="text-primary text-xs whitespace-nowrap md:text-sm font-semibold">
							{selectedCategory?.category_name}
						</p>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="11"
							height="11"
							viewBox="0 0 11 11"
							fill="none"
							className="shrink-0 max-md:h-1 max-md:w-1">
							<circle cx="5.5" cy="5.94116" r="5" fill="#D9D9D9" />
						</svg>
						<p className="text-primary text-xs whitespace-nowrap md:text-sm font-semibold">
							{selectedService?.name}
						</p>
					</div>
					<div className="flex items-center gap-x-2">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="24"
							height="25"
							viewBox="0 0 24 25"
							fill="none"
							className="shrink-0 max-md:h-[18px] max-md:w-[18px]">
							<path
								d="M14.8846 19.129C14.3 19.129 13.8013 18.9226 13.3885 18.5097C12.9756 18.0969 12.7692 17.5982 12.7692 17.0136C12.7692 16.429 12.9756 15.9303 13.3885 15.5174C13.8013 15.1046 14.3 14.8982 14.8846 14.8982C15.4692 14.8982 15.9679 15.1046 16.3808 15.5174C16.7936 15.9303 17 16.429 17 17.0136C17 17.5982 16.7936 18.0969 16.3808 18.5097C15.9679 18.9226 15.4692 19.129 14.8846 19.129ZM5.61538 22.129C5.15513 22.129 4.77083 21.9748 4.4625 21.6665C4.15417 21.3581 4 20.9738 4 20.5136V7.74434C4 7.28409 4.15417 6.8998 4.4625 6.59147C4.77083 6.28314 5.15513 6.12897 5.61538 6.12897H7.3846V3.89819H8.46152V6.12897H15.6154V3.89819H16.6154V6.12897H18.3846C18.8449 6.12897 19.2292 6.28314 19.5375 6.59147C19.8458 6.8998 20 7.28409 20 7.74434V20.5136C20 20.9738 19.8458 21.3581 19.5375 21.6665C19.2292 21.9748 18.8449 22.129 18.3846 22.129H5.61538ZM5.61538 21.129H18.3846C18.5385 21.129 18.6795 21.0649 18.8077 20.9367C18.9359 20.8085 19 20.6674 19 20.5136V11.7443H5V20.5136C5 20.6674 5.0641 20.8085 5.1923 20.9367C5.32052 21.0649 5.46154 21.129 5.61538 21.129ZM5 10.7443H19V7.74434C19 7.59051 18.9359 7.44949 18.8077 7.32127C18.6795 7.19307 18.5385 7.12897 18.3846 7.12897H5.61538C5.46154 7.12897 5.32052 7.19307 5.1923 7.32127C5.0641 7.44949 5 7.59051 5 7.74434V10.7443Z"
								fill="#123258"
							/>
						</svg>
						<p className="text-primary text-xs md:text-sm">
							{DateTime.fromISO(appointmentDate).setLocale(appLanguage).toFormat('LLL dd, cccc')}
						</p>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="20"
							height="21"
							viewBox="0 0 20 21"
							fill="none"
							className="shrink-0 max-md:h-[18px] max-md:w-[18px]">
							<path
								d="M9.99935 19.2738C14.6017 19.2738 18.3327 15.5429 18.3327 10.9405C18.3327 6.33814 14.6017 2.60718 9.99935 2.60718C5.39698 2.60718 1.66602 6.33814 1.66602 10.9405C1.66602 15.5429 5.39698 19.2738 9.99935 19.2738Z"
								stroke="#123258"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
							<path
								d="M10 5.94116V10.9412L13.3333 12.6078"
								stroke="#123258"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
						<p className="text-primary text-xs md:text-sm">
							{timeSlot} -{' '}
							{DateTime.fromFormat(timeSlot, 'HH:mm')
								.plus({
									minutes: Number(selectedService?.duration ?? 0)
								})
								.toFormat('HH:mm')}
						</p>
					</div>
				</div>
			)}
			{questions.length > 0 && (
				<h3 className="text-tertiary max-md:text-sm">{t(tKey('titles.requiredDetails'))}</h3>
			)}
			{questions.length > 0 && (
				<h1 className="text-primary text-xl font-bold pb-4 border-b border-[#D3E3F1]">
					{t(tKey('titles.questions'))}
				</h1>
			)}
			{questions
				.filter(question => question.is_active === 'aktiv')
				.sort((a, b) => a.order_num - b.order_num)
				.map(question => (
					<div key={question._id} className="flex flex-col gap-y-4">
						<h3 className="text-base text-primary max-md:text-xs font-semibold">
							{question.label}
						</h3>
						{question.type === 'radio' && (
							<div className="flex flex-row gap-x-3">
								<div className={clsx('flex items-center space-x-2')}>
									<input
										name={question.label}
										type="radio"
										required={question.is_required === 'yes'}
										onChange={e => handleChange(e, question.label, question.group)}
										value="yes"
										checked={
											answeredQuestions[
												question.label
													.replace('?', '')
													.replace(/\s*\([^)]*\)/, '')
													.trim()
											]?.value === 'yes'
										}
										className="w-4 h-4 ring-0 checked:ring-0 text-primary border border-[#D3E3F1]"
									/>
									<p className="text-primary max-md:text-xs">{t(tKey('labels.yes'))}</p>
								</div>
								<div className={clsx('flex items-center space-x-2')}>
									<input
										name={question.label}
										type="radio"
										required={question.is_required === 'yes'}
										onChange={e => handleChange(e, question.label, question.group)}
										value="no"
										checked={
											answeredQuestions[
												question.label
													.replace('?', '')
													.replace(/\s*\([^)]*\)/, '')
													.trim()
											]?.value === 'no'
										}
										className="w-4 h-4 ring-0 checked:ring-0 text-primary border border-[#D3E3F1]"
									/>
									<p className="text-primary max-md:text-xs">{t(tKey('labels.no'))}</p>
								</div>
							</div>
						)}

						{question.type === 'select' && (
							<Select
								className="w-full text-primary max-md:text-xs md:font-normal max-md:font-normal"
								required={question.is_required === 'yes'}
								onChange={e => handleChange(e, question.label, question.group)}
								name={question.label}>
								<option value="">{question.label}</option>
								{question.options.map(option => (
									<option key={option} value={option.value}>
										{option.label}
									</option>
								))}
							</Select>
						)}

						{question.type === 'textarea' && (
							<textarea
								onChange={e => handleChange(e, question.label, question.group)}
								required={question.is_required === 'yes'}
								placeholder={question.label}
								name={question.label}
								rows={3}
								className="w-full rounded font-normal py-3 bg-transparent focus:ring-0 border disabled:text-gray-500 focus:border-secondary focus-visible:outline-none border-[#D3E3F1] text-primary placeholder-[#7F9AB2] placeholder:text-sm focus:outline-none text-sm"
							/>
						)}

						{question.type !== 'select' &&
							question.type !== 'radio' &&
							question.type !== 'textarea' && (
								<Input
									name={question.label}
									onChange={e => handleChange(e, question.label, question.group)}
									type={question.input_type}
									className="max-md:text-xs"
									required={question.is_required === 'yes'}
								/>
							)}
					</div>
				))}
			<h1 className="text-primary text-xl font-bold pb-4 border-b border-[#D3E3F1]">
				{t(tKey('titles.additionalInformation'))}
			</h1>
			<textarea
				onChange={e => setAdditionalInfo(e.target.value)}
				value={additionalInfo}
				placeholder={t(tKey('titles.additionalInformation'))}
				name="additionalInformation"
				rows={3}
				className="w-full rounded font-normal py-3 bg-transparent focus:ring-0 border disabled:text-gray-500 focus:border-secondary focus-visible:outline-none border-[#D3E3F1] text-primary placeholder-[#7F9AB2] placeholder:text-sm focus:outline-none text-sm"
			/>
		</div>
	)
}
