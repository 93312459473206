import { Listbox, ListboxButton, ListboxOption, ListboxOptions, Switch } from '@headlessui/react'
import { ChevronDownIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import ReactQuill from 'react-quill'
import { toast } from 'react-toastify'

import { yupResolver } from '@hookform/resolvers/yup'
import clsx from 'clsx'
import { DateTime } from 'luxon'
import * as yup from 'yup'

import { Button } from 'components/app/button'
import { MultiCombobox } from 'components/app/combo-selector'
import { Input } from 'components/inputs/input'
import { Select } from 'components/inputs/select'
import { useAppSelector } from 'hooks'
import employeeService from 'services/employee-service'
import eventService from 'services/event-service'
import { getTKey } from 'utils/language'

import '../appointments/notes.css'

type EventForm = {
	title: string
	description: string
	duration: string
	fromDate: string
	toDate: string
	from: string
	to: string
	allDay: boolean
	guests: string[]
	color: string
	eventType: 'event'
}

interface CreateOrUpdateEventFormProps {
	eventId?: string
	isFixed?: boolean
	onClose: () => void
	onCreation: () => void
}

export const CreateOrUpdateEventForm = ({
	eventId,
	isFixed = true,
	onClose,
	onCreation
}: CreateOrUpdateEventFormProps) => {
	const { t } = useTranslation()
	const tKey = getTKey('calendar.createEvent')

	const auth = useAppSelector(state => state.auth)

	const [employees, setEmployees] = useState<Employee[]>([])
	const [noteValue, setNoteValue] = useState<string>()
	const [selectedEmployee, setSelectedEmployee] = useState<{ id: string; label: string }[]>([])

	const timeRegex = /^([01]\d|2[0-3]):?([0-5]\d)$/

	const schema = yup.object<EventForm>().shape({
		title: yup.string().required(t(tKey('errors.title'))),
		description: yup.string().required(t(tKey('errors.description'))),
		fromDate: yup
			.string()
			.required(t(tKey('errors.fromDate')))
			.test('is-valid-fromDate', t(tKey('errors.fromDateBeforeToDate')), function (value) {
				const { toDate } = this.parent
				if (new Date(value) > new Date(toDate)) {
					return false
				}
				return true
			}),
		toDate: yup
			.string()
			.required(t(tKey('errors.toDate')))
			.test('is-valid-toDate', t(tKey('errors.toDateAfterFromDate')), function (value) {
				const { fromDate } = this.parent
				if (new Date(value) < new Date(fromDate)) {
					return false
				}
				return true
			}),
		color: yup.string().required(t(tKey('errors.colorIndicator'))),
		allDay: yup.boolean(),
		from: yup.string().test('is-valid-from', t(tKey('errors.invalidTime')), function (value) {
			const { allDay } = this.parent
			if (allDay) return true
			if (!value) {
				return this.createError({
					path: 'from',
					message: t(tKey('errors.from'))
				})
			}
			if (!timeRegex.test(value)) return false
			return true
		}),
		to: yup.string().test('is-valid-to', t(tKey('errors.invalidTime')), function (value) {
			const { from, allDay } = this.parent
			if (allDay) return true
			if (!value) {
				return this.createError({
					path: 'to',
					message: t(tKey('errors.to'))
				})
			}
			if (!timeRegex.test(value)) return false

			const fromTime = new Date(`1970-01-01T${from}:00Z`)
			const toTime = new Date(`1970-01-01T${value}:00Z`)

			if (toTime < fromTime) {
				return this.createError({
					path: 'to',
					message: t(tKey('errors.toBeforeFrom'))
				})
			}
			return true
		})
	})

	const {
		register,
		handleSubmit,
		watch,
		setValue,
		reset,
		control,
		formState: { errors }
	} = useForm<EventForm>({
		resolver: yupResolver(schema as any),
		defaultValues: {
			allDay: false
		},
		mode: 'all'
	})

	const colorOptions = [
		'#616161',
		'#D30915',
		'#F2522C',
		'#167F46',
		'#7A88C9',
		'#4154B3',
		'#3BB57B',
		'#F5BE3B',
		'#E47D76'
	]

	const startTime = watch('from')
	const endTime = watch('to')
	const startDate = watch('fromDate')
	const endDate = watch('toDate')
	const allDay = watch('allDay')

	const [addedById, setAddedById] = useState<string>()

	useEffect(() => {
		employeeService.getCompanyEmployees().then(res => setEmployees(res))
	}, [])

	useEffect(() => {
		if (startDate && endDate) {
			if (
				DateTime.fromISO(endDate)
					.startOf('day')
					.diff(DateTime.fromISO(startDate).startOf('day'), 'days').days > 0
			) {
				setValue('allDay', true)
			}
		}
	}, [startDate, endDate])

	useEffect(() => {
		if (eventId) {
			eventService.getEventById(eventId).then(res => {
				reset({
					title: res.title,
					description: res.description,
					duration: res.duration,
					color: res.colorCode,
					allDay: res.allDay,
					fromDate: DateTime.fromMillis(res.from).toFormat('yyyy-LL-dd'),
					toDate: DateTime.fromMillis(res.to).toFormat('yyyy-LL-dd'),
					from: DateTime.fromMillis(res.from).toFormat('HH:mm'),
					to: DateTime.fromMillis(res.to).toFormat('HH:mm')
				})
				setNoteValue(res.notes)
				setAddedById(res.addedBy._id)
				const selectedEmployee = employees.filter(employee =>
					res.guests.some(guest => guest._id === employee._id)
				)
				setSelectedEmployee(
					selectedEmployee.map(employee => ({
						id: employee._id,
						label: employee.fname + ' ' + employee.lname
					}))
				)
			})
		}
	}, [eventId, employees])

	const onSubmit = (data: EventForm) => {
		if (eventId) {
			eventService
				.updateEvent(eventId, {
					title: data.title,
					description: data.description,
					duration: data.duration,
					notes: noteValue,
					guests:
						selectedEmployee.length === 0 ? [] : selectedEmployee.map(employee => employee.id),
					allDay: data.allDay,
					colorCode: data.color,
					id_company: auth.companyId,
					from: data.allDay
						? DateTime.fromISO(data.fromDate).startOf('day').toMillis()
						: DateTime.fromISO(`${data.fromDate}T${data.from}`).toMillis(),
					to: data.allDay
						? DateTime.fromISO(data.toDate).endOf('day').toMillis()
						: DateTime.fromISO(`${data.toDate}T${data.to}`).toMillis()
				})
				.then(() => {
					toast.success(t(tKey('toast.eventUpdate')))
					onCreation()
				})
				.catch(err => toast.error(err?.response?.data?.message ?? t(tKey('toast.eventError'))))
		} else {
			eventService
				.createEvent({
					title: data.title,
					notes: noteValue,
					description: data.description,
					duration: data.duration,
					guests:
						selectedEmployee.length === 0 ? [] : selectedEmployee.map(employee => employee.id),
					allDay: data.allDay,
					colorCode: data.color,
					id_company: auth.companyId,
					from: data.allDay
						? DateTime.fromISO(data.fromDate).startOf('day').toMillis()
						: DateTime.fromISO(`${data.fromDate}T${data.from}`).toMillis(),
					to: data.allDay
						? DateTime.fromISO(data.toDate).endOf('day').toMillis()
						: DateTime.fromISO(`${data.toDate}T${data.to}`).toMillis()
				})
				.then(() => {
					toast.success(t(tKey('toast.eventCreation')))
					onCreation()
				})
				.catch(err => toast.error(err?.response?.data?.message ?? t(tKey('toast.eventError'))))
		}
	}

	return (
		<form
			onSubmit={handleSubmit(onSubmit)}
			className={clsx(
				'flex overflow-auto bg-white flex-col gap-y-3',
				isFixed
					? 'md:fixed md:inset-y-0 md:right-0 z-[999] md:w-[360px] md:min-h-screen'
					: 'md:px-8 py-6 max-md:px-5'
			)}>
			{isFixed && (
				<div className="flex max-md:hidden h-11 justify-between items-center py-1.5 px-4">
					<h1 className="font-domine font-bold leading-6 text-[#1C1C1C]">
						{t(tKey('headings.addEvent'))}
					</h1>
					<XMarkIcon onClick={onClose} className="h-5 w-5 cursor-pointer text-[#1C1C1C]" />
				</div>
			)}
			<div className="flex flex-col gap-6 p-5">
				<Input
					type="text"
					register={register}
					disabled={addedById ? auth._id !== addedById : false}
					name="title"
					labelText={t(tKey('labels.title'))}
					errors={errors}
				/>
				<div className="flex flex-col">
					<textarea
						{...register('description')}
						disabled={addedById ? auth._id !== addedById : false}
						placeholder={t(tKey('labels.description'))}
						rows={2.5}
						className="w-full disabled:text-gray-500 rounded text-sm placeholder:text-sm font-normal pl-4 py-3 bg-transparent focus:ring-0 border focus:border-secondary border-[#D3E3F1] text-primary placeholder-[#7F9AB2] focus:outline-none max-md:text-sm"
					/>
					{errors.description && (
						<p className="text-xs text-red-500 mt-1">{errors.description.message}</p>
					)}
				</div>
				<div className="grid grid-cols-1 gap-y-6 md:grid-cols-2 items-start gap-x-2.5">
					<Input
						type="date"
						register={register}
						name="fromDate"
						disabled={addedById ? auth._id !== addedById : false}
						labelText={t(tKey('labels.fromDate'))}
						max={DateTime.fromISO(endDate).endOf('day').toFormat('yyyy-LL-dd')}
						errors={errors}
					/>
					<Input
						type="date"
						register={register}
						name="toDate"
						disabled={addedById ? auth._id !== addedById : false}
						min={DateTime.fromISO(startDate).startOf('day').toFormat('yyyy-LL-dd')}
						labelText={t(tKey('labels.endDate'))}
						errors={errors}
					/>
				</div>
				<div className="flex justify-between items-center">
					<h5 className="text-sm text-primary">{t(tKey('labels.allDay'))}</h5>
					<Controller
						control={control}
						name="allDay"
						render={({ field: { onChange, value } }) => (
							<Switch
								disabled={addedById ? auth._id !== addedById : false}
								checked={value}
								onChange={onChange}
								name="allDay"
								className="group inline-flex h-4 w-8 items-center rounded-full bg-[#9E9E9E] transition data-[checked]:bg-primary-light">
								<span className="size-2.5 translate-x-1 rounded-full bg-white transition group-data-[checked]:translate-x-[18px]" />
							</Switch>
						)}
					/>
				</div>
				<div className="grid grid-cols-1 gap-y-6 md:grid-cols-2 items-start gap-x-2.5">
					<Input
						type="time"
						disabled={allDay || (addedById ? auth._id !== addedById : false)}
						register={register}
						name="from"
						max={endTime}
						labelText={t(tKey('labels.from'))}
						errors={errors}
					/>
					<Input
						type="time"
						register={register}
						disabled={allDay || (addedById ? auth._id !== addedById : false)}
						name="to"
						min={startTime}
						labelText={t(tKey('labels.to'))}
						errors={errors}
					/>
				</div>
				<Select
					labelText={t(tKey('labels.repeat'))}
					name="duration"
					disabled={addedById ? auth._id !== addedById : false}
					register={register}
					errors={errors}>
					<option value="none">{t(tKey('labels.never'))}</option>
					<option value="daily">{t(tKey('labels.everyday'))}</option>
					<option value="weekly">{t(tKey('labels.everyWeek'))}</option>
					<option value="monthly">{t(tKey('labels.everyMonth'))}</option>
					<option value="yearly">{t(tKey('labels.everyYear'))}</option>
				</Select>
				<div className="relative overflow-visible rounded-md">
					<label
						htmlFor="agb"
						className="absolute z-10 -top-2.5 text-xs cursor-text px-0.5 text-primary bg-white left-3">
						{t(tKey('labels.note'))}
					</label>
					<ReactQuill
						className="treatment editor"
						theme="snow"
						readOnly={addedById ? auth._id !== addedById : false}
						placeholder={t('calendar.eventDetails.labels.enterNoteHere')}
						value={noteValue}
						onChange={setNoteValue}
					/>
				</div>
				<MultiCombobox
					placeholder={t(tKey('labels.participants'))}
					options={employees.map(employee => ({
						label: `${employee.fname} ${employee.lname}`,
						id: employee._id
					}))}
					disabled={addedById ? auth._id !== addedById : false}
					selectedItems={selectedEmployee}
					onChange={value => {
						const labelCountMap = new Map()
						value.forEach(item => {
							const count = labelCountMap.get(item.label) || 0
							labelCountMap.set(item.label, count + 1)
						})
						const uniqueValue = value.filter(item => labelCountMap.get(item.label) === 1)
						setSelectedEmployee(uniqueValue)
					}}
				/>

				<div className="flex flex-col gap-y-1 cursor-pointer">
					<Controller
						control={control}
						name="color"
						render={({ field: { onChange, value } }) => (
							<Listbox
								disabled={addedById ? auth._id !== addedById : false}
								value={value}
								onChange={onChange}>
								<ListboxButton
									className={clsx(
										'relative w-full rounded font-normal py-3 bg-transparent focus:ring-0 border disabled:text-gray-500 focus:border-secondary focus-visible:outline-none border-[#D3E3F1] text-primary placeholder-[#7F9AB2] placeholder:text-sm focus:outline-none text-sm'
									)}>
									<label
										htmlFor="name"
										className="absolute -top-2.5 text-xs cursor-text px-0.5 text-primary bg-white left-3">
										{t(tKey('labels.colorIndicator'))}
									</label>
									<div style={{ background: value }} className="h-4 w-4 ml-3 rounded-[2px]" />
									<ChevronDownIcon
										className="group pointer-events-none absolute top-2.5 right-2.5 size-4"
										aria-hidden="true"
									/>
								</ListboxButton>
								<ListboxOptions
									anchor="bottom"
									transition
									className={clsx(
										'z-[999] mt-1 flex justify-between overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm',
										'transition duration-100 ease-in data-[leave]:data-[closed]:opacity-0'
									)}>
									{colorOptions.map(color => (
										<ListboxOption
											key={color}
											value={color}
											className={clsx(
												'group flex cursor-default items-center gap-2 rounded-lg py-1.5 px-[12.5px] select-none data-[focus]:bg-[#13BAB40F] data-[focus]:text-primary',
												isFixed ? 'md:px-[11px]' : 'md:px-[38px]'
											)}>
											<div style={{ background: color }} className="h-3 w-3 rounded-[2px]" />
										</ListboxOption>
									))}
								</ListboxOptions>
							</Listbox>
						)}
					/>

					{errors?.color && <p className="text-xs text-red-500">{errors.color.message}</p>}
				</div>
			</div>
			<div className="p-3 flex justify-between gap-x-2">
				<Button
					disabled={addedById ? auth._id !== addedById : false}
					className={clsx('w-full rounded flex-1', {
						'!bg-none !bg-gray-400': addedById ? auth._id !== addedById : false
					})}>
					{eventId ? t(tKey('buttons.updateEvent')) : t(tKey('buttons.createEvent'))}
				</Button>
			</div>
		</form>
	)
}
