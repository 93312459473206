import { Combobox, Tab, TabGroup, TabList, Transition } from '@headlessui/react'
import { ChevronDownIcon, ClockIcon } from '@heroicons/react/24/outline'
import { Tooltip as ReactTooltip } from 'flowbite-react'
import { Fragment, useEffect, useMemo, useState } from 'react'
import 'react-calendar/dist/Calendar.css'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import clsx from 'clsx'
import { DateTime } from 'luxon'

import {
	AppartmentIcon,
	BlockIcon,
	CancelledIcon,
	CompleteIcon,
	DollarIcon,
	EventCalendarIcon,
	ExpandIcon,
	PendingIcon
} from 'assets/icons'
import { ReactComponent as AppointmentCalendarIcon } from 'assets/svgs/appointment-calendar.svg'
import { ReactComponent as PlusIcon } from 'assets/svgs/plus-icon.svg'
import { ReactComponent as SpeakerIcon } from 'assets/svgs/speaker.svg'
import { ReactComponent as TaskCardIcon } from 'assets/svgs/task-card.svg'
import { ReactComponent as TaskListIcon } from 'assets/svgs/task-list.svg'
import { AppLayout } from 'components/app/layout'
import { MenuDropdown } from 'components/app/menu'
import { StatsCard } from 'components/cards/stats-cards'
import { AppointmentStatus, mappedAppointmentStatuses } from 'constants/constants'
import { useAppSelector } from 'hooks'
import useIsMobile from 'hooks/useIsMobile'
import Calendar from 'react-calendar'
import {
	Bar,
	BarChart,
	CartesianGrid,
	Cell,
	Label,
	Pie,
	PieChart,
	Rectangle,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis
} from 'recharts'
import appointmentService from 'services/appointment-service'
import eventService from 'services/event-service'
import taskService from 'services/task-service'
import { getYears } from 'utils/date'
import { getTKey } from 'utils/language'

export const Dashboard = () => {
	const { i18n, t } = useTranslation()
	const isMobile = useIsMobile()
	const navigate = useNavigate()

	const tKey = getTKey('dashboard')
	const yearList = getYears()

	const auth = useAppSelector(state => state.auth)

	const [appointmentStats, setAppointmentStats] = useState<
		{ title: string; value: number; link: string }[]
	>([])
	const [eventStats, setEventStats] = useState<{ title: string; value: number; link: string }[]>([])
	const [companyAppointments, setCompanyAppointments] = useState<Appointment[]>([])
	const [tasks, setTasks] = useState<Task[]>([])
	const [allAppointments, setAllAppointments] = useState<Appointment[]>([])
	const [calendarValue, setCalendarValue] = useState<CalendarValue>(DateTime.now().toJSDate())
	const [selectedRange, setSelectedRange] = useState('monthly')
	const [selectedDuration, setSelectedDuration] = useState(DateTime.now().toFormat('yyyy'))
	const [query, setQuery] = useState('')
	const [selectedStat, setSelectedStat] = useState('Appointment')
	const [events, setEvents] = useState<CustomEvent[]>([])
	const [currentIndex, setCurrentIndex] = useState(5)
	const [weeklyRevenue, setWeeklyRevenue] = useState<WeeklyRevenue[]>([])
	const [monthlyRevenue, setMonthlyRevenue] = useState<MonthlyRevenue[]>([])
	const [selectedTaskType, setSelectedTaskType] = useState(t(tKey('labels.backlog')))

	const backlogTasks = tasks.filter(task => task.status === 'not started')
	const inProgressTasks = tasks.filter(task => task.status === 'in progress')
	const completedTasks = tasks.filter(task => task.status === 'completed')

	useEffect(() => {
		if (auth.is_adminUser === true) {
			appointmentService.getCompanyAppointments(auth.companyId).then(res => setAllAppointments(res))
		} else {
			appointmentService.getCompanyAppointments(auth.companyId).then(res => setAllAppointments(res))
		}
	}, [auth])

	useEffect(() => {
		appointmentService.getAppointmentStats().then(res =>
			setAppointmentStats([
				{
					title: t(tKey('stats.labels.totalAppointments')),
					value: res?.totalAppointment as number,
					link: '/appointments'
				},
				{
					title: t(tKey('stats.labels.pendingAppointments')),
					value: res?.pendent as number,
					link: `/appointments?status=${mappedAppointmentStatuses[AppointmentStatus.PENDENT]}`
				},
				{
					title: t(tKey('stats.labels.cancelledAppointments')),
					value: res?.canceled as number,
					link: `/appointments?status=${mappedAppointmentStatuses[AppointmentStatus.CANCELLED]}`
				},

				{
					title: t(tKey('stats.labels.completeAppointments')),
					value: res?.abschluss as number,
					link: `/appointments?status=${mappedAppointmentStatuses[AppointmentStatus.COMPLETED]}`
				}
			])
		)
		eventService.getEventStatsByTime().then(res =>
			setEventStats([
				{
					title: t(tKey('stats.labels.upcomingEvents')),
					value: res?.upcomingCount as number,
					link: '/events'
				},
				{
					title: t(tKey('stats.labels.pastEvents')),
					value: res?.pastCount as number,
					link: `/events`
				}
			])
		)
		if (auth.is_adminUser === true) {
			appointmentService
				.getCompanyAppointments(auth.companyId)
				.then(res => setCompanyAppointments(res))
		} else {
			appointmentService
				.getCompanyAppointments(auth.companyId)
				.then(res => setCompanyAppointments(res))
		}
		taskService
			.getAllTasks()
			.then(res => setTasks(res))
			.catch(err => {
				if (err?.response?.status?.message === 'No token provided!') {
					window.location.reload()
				}
			})
		eventService.getAllEvents().then(res => {
			setEvents(res)
		})
	}, [])

	useEffect(() => {
		if (selectedRange === 'monthly') {
			appointmentService.getRevenueStatsByWeek().then(data => setWeeklyRevenue(data))
		} else {
			if (selectedDuration) {
				appointmentService
					.getRevenueStatsByYear(Number(selectedDuration))
					.then(data => setMonthlyRevenue(data))
			}
		}
	}, [selectedDuration, selectedRange])

	const filteredOptions = useMemo(() => {
		if (yearList) {
			return yearList.filter(year => {
				return (
					query === '' || year.replace(/\s+/g, '').includes(query.toLowerCase().replace(/\s+/g, ''))
				)
			})
		} else {
			return yearList
		}
	}, [yearList, query])

	const tasksByType = {
		[t(tKey('labels.backlog'))]: backlogTasks,
		[t(tKey('labels.inProgress'))]: inProgressTasks,
		[t(tKey('labels.completed'))]: completedTasks
	} as { [type: string]: Task[] }

	const data = [
		{ name: 'Appointments', value: companyAppointments?.length },
		{ name: 'Events', value: events?.length }
	]

	const COLORS = ['#61BC5F', '#5555FB']

	return (
		<AppLayout title={t(tKey('titles.dashboard'))} isHeaderVisible={!isMobile}>
			<div className="flex max-md:overflow-x-hidden flex-col bg-[#CFD8DC99] md:gap-6 max-md:p-5 md:px-6 md:py-6">
				<div className="flex max-md:flex-col-reverse max-md:gap-y-3 items-center justify-between gap-x-6">
					<div className="bg-[#001E00] flex flex-col basis-full md:basis-[75%] w-full rounded-xl p-3 md:py-3 md:px-6 gap-3 md:gap-5">
						<div className="flex items-center gap-x-2 justify-between w-full">
							<h1 className="font-domine text-sm md:text-lg text-white">
								{t(tKey('titles.totalBookings'))}
							</h1>
							<TabGroup>
								<TabList className="border border-[#FFFFFF99] grid grid-cols-2 rounded-full">
									<Tab
										onClick={() => setSelectedStat('Appointment')}
										className="rounded-l-full overflow-hidden whitespace-nowrap text-ellipsis py-1 px-1.5 md:px-4 text-xs md:text-sm font-semibold text-white/80 focus:outline-none data-[selected]:bg-[#FFFFFF33] data-[hover]:bg-[#FFFFFF33] data-[selected]:data-[hover]:bg-[#FFFFFF33]">
										{t(tKey('labels.appointments'))}
									</Tab>
									<Tab
										onClick={() => setSelectedStat('Event')}
										className="rounded-r-full overflow-hidden whitespace-nowrap text-ellipsis py-1 px-1.5 md:px-4 text-xs md:text-sm font-semibold text-white/80 focus:outline-none data-[selected]:bg-[#FFFFFF33] data-[hover]:bg-[#FFFFFF33] data-[selected]:data-[hover]:bg-[#FFFFFF33]">
										{t(tKey('labels.events'))}
									</Tab>
								</TabList>
							</TabGroup>
						</div>

						<div className="flex max-md:flex-col md:items-center gap-6">
							<div className="flex md:flex-col md:basis-[20%] max-md:gap-2 md:gap-y-[15px] items-center">
								<div className="bg-primary-light rounded-full size-2" />
								<h1 className="font-domine text-[18px] md:text-[32px] leading-5 md:leading-9 text-white">
									{selectedStat === 'Event' ? events.length : appointmentStats[0]?.value}
								</h1>
								<p className="font-medium text-[#FFFFFFCC] max-md:text-xs leading-[16px] md:leading-[18px] ">
									{selectedStat === 'Event'
										? t(tKey('labels.events'))
										: t(tKey('labels.appointments'))}
								</p>
							</div>
							<div className="flex md:w-full md:basis-[80%] max-md:overflow-x-auto md:items-center gap-3 md:gap-6">
								{selectedStat === 'Event' &&
									eventStats.map((eventStat, index) => (
										<StatsCard
											key={eventStat.title}
											title={eventStat.title}
											value={eventStat.value}
											percentage={
												(Number(eventStat?.value) / (events.length === 0 ? 1 : events.length)) * 100
											}
											icon={index === 0 ? EventCalendarIcon : CompleteIcon}
											link={eventStat.link}
											className={clsx(index === 0 ? 'bg-[#BF943D]' : 'bg-[#38A738]')}
										/>
									))}
								{selectedStat === 'Appointment' &&
									appointmentStats
										.slice(1)
										.map((appointmentStat, index) => (
											<StatsCard
												key={appointmentStat.title}
												title={appointmentStat.title}
												value={appointmentStat.value}
												percentage={
													(Number(appointmentStat?.value) /
														(Number(appointmentStats[0]?.value) === 0
															? 1
															: Number(appointmentStats[0]?.value))) *
													100
												}
												icon={
													index === 0 ? PendingIcon : index === 1 ? CancelledIcon : CompleteIcon
												}
												link={appointmentStat.link}
												className={clsx(
													index === 0
														? 'bg-[#BF943D]'
														: index === 1
															? 'bg-[#BE454B]'
															: 'bg-[#38A738]'
												)}
											/>
										))}
							</div>
						</div>
					</div>

					<div
						style={{ boxShadow: '0px 4px 24px 0px #CFD8DC4D' }}
						className="md:basis-[25%] rounded-xl py-4 px-6 bg-[#B790D899] basis-full border border-[#CFD8DC80] flex md:flex-col gap-y-8">
						<div className="md:h-[136px] overflow-hidden flex flex-col">
							<h1 className="font-domine max-md:text-sm font-bold text-primary leading-5">
								{t(tKey('labels.welcome'))} {auth?.fname + ' ' + auth?.lname}
							</h1>
							<p className="md:text-sm text-xs mt-4 text-primary">
								{t(tKey('labels.uniqueBlocks'))}
							</p>
							<img
								src={BlockIcon}
								className="-rotate-4 max-md:hidden ml-auto -mt-7 object-cover size-36"
							/>
						</div>
						<img
							src={BlockIcon}
							className="-rotate-4 md:hidden ml-auto -mt-4 object-cover size-14"
						/>
					</div>
				</div>

				<div
					style={{ backgroundPositionY: '50px' }}
					className="md:basis-[25%] my-3 md:hidden bg-white bg-booking-overview bg-right-bottom bg-cover bg-no-repeat border border-[#CFD8DC] rounded-xl p-4 flex flex-col gap-3 md:gap-4">
					<h1 className="font-domine font-bold text-primary text-sm">
						{t(tKey('titles.bookingsOverview'))}
					</h1>
					<div className="flex md:flex-col max-md:justify-between items-center md:gap-4">
						<PieChart width={isMobile ? 120 : 162} height={isMobile ? 120 : 162}>
							<Pie
								data={data}
								cx={isMobile ? 60 : 81}
								cy={isMobile ? 60 : 81}
								innerRadius={isMobile ? 45 : 55}
								outerRadius={isMobile ? 55 : 70}
								stroke="none"
								fill="#8884d8"
								cornerRadius={10}
								paddingAngle={5}
								dataKey="value">
								{data.map((entry, index) => (
									<Cell key={`cell-${index}`} fill={COLORS[index % COLORS?.length]} />
								))}
								<Label
									value={`${companyAppointments?.length + events?.length} ${t(
										tKey('labels.total')
									)}`}
									position="center"
									fill="#001E00"
									style={
										isMobile
											? { fontSize: '14px', fontWeight: 600 }
											: { fontSize: '19px', fontWeight: 600 }
									}
								/>
							</Pie>
						</PieChart>
						<div className="flex max-md:flex-col md:items-center gap-[31px] justify-between">
							<div className="flex items-center gap-[9px]">
								<div className="bg-primary-light size-3 rounded-[1px]" />
								<div className="flex flex-col gap-1">
									<p className="text-primary italic font-bold text-sm">
										{companyAppointments?.length}
									</p>
									<p className="text-primary italic text-sm">{t(tKey('labels.appointments'))}</p>
								</div>
							</div>

							<div className="flex items-center gap-[9px]">
								<div className="bg-[#5555FB] size-3 rounded-[1px]" />
								<div className="flex flex-col gap-1">
									<p className="text-primary italic font-bold text-sm">{events?.length}</p>
									<p className="text-primary italic text-sm">{t(tKey('labels.events'))}</p>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="flex max-md:flex-col md:items-center md:justify-between gap-y-3 md:gap-x-6">
					<div className="md:basis-[75%] grid grid-cols-1 md:grid-cols-2 gap-3 md:gap-6 items-start">
						<div className="border w-full bg-white p-3 md:p-4 border-[#CFD8DC] flex flex-col gap-3 md:gap-4 rounded-xl">
							<div className="flex items-start md:items-center justify-between">
								<div className="flex  max-md:mt-1 items-center gap-1.5">
									<img src={DollarIcon} className="max-md:size-[18px]" />
									<h1 className="font-domine font-bold text-primary text-sm">
										{t(tKey('titles.totalRevenue'))}
									</h1>
								</div>
								<div className="flex max-md:flex-col gap-2 md:items-center">
									<MenuDropdown
										options={[t(tKey('menu.month')), t(tKey('menu.year'))]}
										value={
											selectedRange === 'monthly' ? t(tKey('menu.month')) : t(tKey('menu.year'))
										}
										shouldIgnore
										className="!left-0"
										onChange={(value: string) => {
											if (value === t(tKey('menu.month'))) {
												setSelectedRange('monthly')
											} else {
												setSelectedRange('yearly')
											}
										}}
									/>
									{selectedRange === 'yearly' && (
										<Combobox
											value={selectedDuration}
											onChange={(value: string) => setSelectedDuration(value)}>
											<div className="relative">
												<div className="relative cursor-default w-28 md:w-32 overflow-hidden bg-white text-left border-none focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
													<Combobox.Input
														placeholder={t(tKey('placeholders.year'))}
														className="border border-border max-md:text-xs pr-7 w-full !py-[6px] !md:py-[10px] rounded focus:border-secondary text-sm leading-5 text-gray-900 focus:ring-0"
														displayValue={option => selectedDuration}
														onChange={event => setQuery(event.target.value)}
													/>

													<Combobox.Button className="absolute inset-y-0 flex items-center right-0 pr-2">
														<svg
															xmlns="http://www.w3.org/2000/svg"
															width="20"
															height="20"
															viewBox="0 0 20 20"
															fill="none">
															<path
																d="M9.99988 12.2178C9.89945 12.2178 9.80597 12.2018 9.71944 12.1697C9.6329 12.1377 9.55064 12.0826 9.47265 12.0047L5.72746 8.25947C5.61207 8.14409 5.55305 7.99906 5.55038 7.82438C5.5477 7.6497 5.60673 7.502 5.72746 7.38128C5.84819 7.26056 5.99455 7.2002 6.16655 7.2002C6.33855 7.2002 6.48491 7.26056 6.60563 7.38128L9.99988 10.7755L13.3941 7.38128C13.5095 7.2659 13.6545 7.20687 13.8292 7.20419C14.0039 7.20153 14.1516 7.26056 14.2723 7.38128C14.393 7.502 14.4534 7.64837 14.4534 7.82038C14.4534 7.99238 14.393 8.13874 14.2723 8.25947L10.5271 12.0047C10.4491 12.0826 10.3669 12.1377 10.2803 12.1697C10.1938 12.2018 10.1003 12.2178 9.99988 12.2178Z"
																fill="#123258"
															/>
														</svg>
													</Combobox.Button>
												</div>
												<Transition
													as={Fragment}
													leave="transition ease-in duration-100"
													leaveFrom="opacity-100"
													leaveTo="opacity-0">
													<Combobox.Options className="absolute mt-1 z-50 max-h-60 min-w-max w-full overflow-auto rounded-md bg-white pt-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
														{Array.isArray(getYears()) &&
															filteredOptions.map((option, index) => (
																<Combobox.Option
																	key={index}
																	className={({ active }) =>
																		`relative cursor-default select-none py-0.5 ${
																			active ? 'bg-[#13BAB40F] text-white' : 'text-gray-900'
																		}`
																	}
																	value={option}>
																	{({ selected, active }) => (
																		<span className="flex gap-x-3 px-4 md:px-6 py-1.5 items-center">
																			<span className="flex-col group items-start text-primary font-semibold w-full overflow-hidden text-ellipsis text-xs md:text-sm whitespace-nowrap grow hover:bg-[#13BAB40F] flex text-left rounded-md">
																				{option}
																			</span>
																		</span>
																	)}
																</Combobox.Option>
															))}
													</Combobox.Options>
												</Transition>
											</div>
										</Combobox>
									)}
								</div>
							</div>
							<h1 className="text-primary-light -mt-3 w-full ml-7 font-domine font-bold md:text-[18px]">
								{selectedRange === 'monthly'
									? weeklyRevenue[0]?.currency +
										' ' +
										new Intl.NumberFormat(undefined, {
											minimumFractionDigits: 2,
											maximumFractionDigits: 2
										}).format(weeklyRevenue?.reduce((agg, curr) => agg + curr.revenue, 0))
									: monthlyRevenue[0]?.currency +
										' ' +
										new Intl.NumberFormat(undefined, {
											minimumFractionDigits: 2,
											maximumFractionDigits: 2
										}).format(monthlyRevenue?.reduce((agg, curr) => agg + curr.revenue, 0))}
							</h1>
							<ResponsiveContainer width="100%" height={isMobile ? 200 : 300}>
								<BarChart
									width={600}
									height={300}
									data={selectedRange === 'monthly' ? weeklyRevenue : monthlyRevenue}>
									<CartesianGrid strokeDasharray="3 3" />
									<XAxis dataKey={selectedRange === 'monthly' ? 'dayOfWeek' : 'month'} />
									<YAxis />
									<Tooltip />
									<Bar
										dataKey="revenue"
										fill="#5A6555"
										radius={[4, 4, 0, 0]}
										activeBar={<Rectangle fill="#61BC5F" stroke="#13BAB4" />}
									/>
								</BarChart>
							</ResponsiveContainer>
						</div>

						<div className="border self-stretch w-full bg-white p-3 md:p-4 border-[#CFD8DC] flex flex-col gap-3 md:gap-4 rounded-xl">
							<div className="flex items-center justify-between">
								<div className="flex items-center gap-1.5">
									<img src={AppartmentIcon} className="max-md:size-[18px]" />
									<h1 className="font-domine font-bold text-primary text-sm">
										{t(tKey('titles.upcomingAppointments'))}
									</h1>
								</div>
								<img
									src={ExpandIcon}
									onClick={() => navigate('/appointments')}
									className="cursor-pointer max-md:size-[18px]"
								/>
							</div>
							<div className="flex flex-col gap-3">
								{companyAppointments.filter(
									appointment =>
										DateTime.fromMillis(appointment.from) > DateTime.now() &&
										appointment.status !== AppointmentStatus.CANCELLED
								)?.length > 0 ? (
									companyAppointments
										.filter(appointment => DateTime.fromMillis(appointment.from) > DateTime.now())
										.slice(0, 3)
										.sort((a, b) => a.from - b.from)
										.map((appointment, index) => (
											<div
												key={appointment._id}
												onClick={() => navigate(`/appointment-details/${appointment._id}`)}
												className={clsx(
													'border cursor-pointer border-[#C3E6CBCC] rounded-md px-3 py-1.5 flex items-center gap-3',
													index % 2 === 0 ? 'bg-[#D4EDDA33]' : 'bg-[#B790D833]'
												)}>
												<div className="flex flex-col gap-1">
													<ReactTooltip
														placement="bottom"
														style="dark"
														className="whitespace-nowrap bg-[#323232] text-white text-sm"
														content={appointment.service_name}>
														<h2 className="text-primary whitespace-nowrap overflow-hidden text-ellipsis font-semibold">
															{appointment.service_name}
														</h2>
													</ReactTooltip>
													<div className="flex items-center gap-2">
														<AppointmentCalendarIcon />
														<p className="text-[#4C6780] text-xs">
															{DateTime.fromMillis(appointment.from).toFormat('LLL dd.yyyy EEEE')}
														</p>
														<ClockIcon className="text-[#4C6780] size-4" />
														<p className="text-[#4C6780] text-xs">
															{DateTime.fromMillis(appointment.from).toFormat('hh:mm a')}
														</p>
													</div>
												</div>
											</div>
										))
								) : (
									<p className="text-tertiary text-sm">{t(tKey('noAppointments'))}</p>
								)}
							</div>
						</div>
					</div>
					<div className="max-md:hidden self-stretch basis-[25%] flex flex-col gap-y-8">
						<Calendar
							className="rounded-2xl h-full w-fit p-4 calendar-icon"
							onChange={setCalendarValue}
							selectRange={false}
							goToRangeStartOnSelect={false}
							next2Label={null}
							prev2Label={null}
							tileDisabled={() => selectedRange === 'allTime'}
							tileClassName={({ date }) => {
								const currentDate = DateTime.fromJSDate(date)
								const isSelected = DateTime.fromJSDate(calendarValue as any)?.hasSame(
									currentDate,
									'day'
								)

								if (isSelected) {
									return 'text-white focus:bg-primary-light bg-primary-light rounded-full font-medium'
								}

								return 'text-gray-700'
							}}
							tileContent={({ date }) => {
								const currentDate = DateTime.fromJSDate(date)
								if (
									allAppointments.some(appointment =>
										DateTime.fromMillis(appointment.from).hasSame(currentDate, 'day')
									)
								) {
									return <div className="mx-auto w-1 h-1 bg-green-500 rounded-full" />
								}
								return null
							}}
							locale={i18n.language}
							value={calendarValue}
							formatShortWeekday={(locale, date) =>
								date.toLocaleDateString(locale, { weekday: 'short' })
							}
						/>
					</div>
				</div>

				<div className="flex max-md:flex-col max-md:mt-3 items-center justify-between gap-3 md:gap-x-6">
					<div className="md:basis-[75%] self-stretch grid grid-cols-1 md:grid-cols-2 gap-3 md:gap-6 items-start">
						<div className="border w-full self-stretch bg-white p-3 md:p-4 border-[#CFD8DC] flex flex-col gap-3 md:gap-4 rounded-xl">
							<div className="flex items-center w-full justify-between">
								<div className="flex items-center gap-1.5">
									<TaskCardIcon className="max-md:size-[18px]" />
									<h1 className="font-domine font-bold text-primary text-sm">
										{t(tKey('titles.tasksList'))}
									</h1>
								</div>
								<PlusIcon
									onClick={() => navigate('/task-manager/create')}
									className="cursor-pointer max-md:size-[18px]"
								/>
							</div>
							<div className="md:px-4 flex flex-col gap-y-4">
								<TabGroup>
									<TabList className="border border-[#FFFFFF99] grid grid-cols-3 rounded-full">
										<Tab
											onClick={() => setSelectedTaskType(t(tKey('labels.backlog')))}
											className="rounded-l-full md:py-2 md:px-3 p-1 flex justify-center gap-1 items-center text-xs font-semibold data-[selected]:text-white data-[hover]:text-white focus:outline-none text-[#ED1649] bg-[#ED164924] data-[selected]:bg-[#ED1649] data-[hover]:bg-[#ED1649] data-[selected]:data-[hover]:bg-[#ED1649]">
											{t(tKey('labels.backlog'))}
											<div className="rounded-full bg-white size-4 flex justify-center items-center px-1">
												<p className="text-primary font-medium text-[11px]">
													{backlogTasks?.length}
												</p>
											</div>
										</Tab>
										<Tab
											onClick={() => setSelectedTaskType(t(tKey('labels.inProgress')))}
											className="md:py-2 md:px-3 p-1 text-xs flex justify-center items-center gap-1 font-semibold data-[selected]:text-white data-[hover]:text-white focus:outline-none text-[#316BD6] bg-[#316BD624] data-[selected]:bg-[#316BD6] data-[hover]:bg-[#316BD6] data-[selected]:data-[hover]:bg-[#316BD6]">
											{t(tKey('labels.inProgress'))}
											<div className="rounded-full bg-white size-4 flex justify-center items-center px-1">
												<p className="text-primary font-medium text-[11px]">
													{inProgressTasks?.length}
												</p>
											</div>
										</Tab>
										<Tab
											onClick={() => setSelectedTaskType(t(tKey('labels.completed')))}
											className="rounded-r-full flex justify-center items-center gap-1 md:py-2 md:px-3 p-1 text-xs font-semibold data-[selected]:text-white data-[hover]:text-white focus:outline-none text-[#61BC5F] bg-[#61BC5F24] data-[selected]:bg-[#61BC5F] data-[hover]:bg-[#61BC5F] data-[selected]:data-[hover]:bg-[#61BC5F]">
											{t(tKey('labels.completed'))}
											<div className="rounded-full bg-white size-4 flex justify-center items-center px-1">
												<p className="text-primary font-medium text-[11px]">
													{completedTasks?.length}
												</p>
											</div>
										</Tab>
									</TabList>
								</TabGroup>
								<div className="flex flex-col gap-3.5">
									{tasksByType[selectedTaskType]?.length > 0 ? (
										tasksByType[selectedTaskType].slice(0, currentIndex).map(task => (
											<div
												onClick={() => navigate(`/task-manager/create/${task._id}`)}
												key={task._id}
												className="flex cursor-pointer items-center gap-3">
												<TaskListIcon
													className={clsx('max-md:size-3.5', {
														'text-[#FF3A44]': selectedTaskType === t(tKey('labels.backlog')),
														'text-[#316BD6]': selectedTaskType === t(tKey('labels.inProgress')),
														'text-[#61BC5F]': selectedTaskType === t(tKey('labels.completed'))
													})}
												/>
												<ReactTooltip
													placement="bottom"
													style="dark"
													className="whitespace-nowrap text-white bg-[#323232] text-sm"
													content={task.taskName}>
													<p className="text-primary whitespace-nowrap overflow-hidden text-ellipsis font-medium text-sm">
														{task.taskName}
													</p>
												</ReactTooltip>
											</div>
										))
									) : (
										<p className="text-tertiary text-sm">{t(tKey('labels.noTasks'))}</p>
									)}
									{tasksByType[selectedTaskType]?.length > currentIndex && (
										<div className="flex items-center gap-3">
											<TaskListIcon
												className={clsx('max-md:size-3.5', {
													'text-[#FF3A44]': selectedTaskType === t(tKey('labels.backlog')),
													'text-[#316BD6]': selectedTaskType === t(tKey('labels.inProgress')),
													'text-[#61BC5F]': selectedTaskType === t(tKey('labels.completed'))
												})}
											/>
											<div
												onClick={() =>
													setCurrentIndex(prev =>
														Math.min(prev + 5, tasksByType[selectedTaskType]?.length)
													)
												}
												className="flex cursor-pointer hover:underline items-center gap-[3px]">
												<p className="text-primary-light font-medium text-[11px]">
													{t(tKey('labels.showMore'))}
												</p>
												<ChevronDownIcon className="size-4 text-primary-light" />
											</div>
										</div>
									)}
								</div>
							</div>
						</div>

						<div className="border w-full self-stretch bg-white p-3 md:p-4 border-[#CFD8DC] flex flex-col gap-3 md:gap-4 rounded-xl">
							<div className="flex items-center w-full justify-between">
								<div className="flex items-center gap-1.5">
									<SpeakerIcon className="max-md:size-[18px]" />
									<h1 className="font-domine font-bold text-primary text-sm">
										{t(tKey('titles.upcomingEvents'))}
									</h1>
								</div>
								<img
									src={ExpandIcon}
									className="cursor-pointer max-md:size-[18px]"
									onClick={() => navigate('/events')}
								/>
							</div>
							<div className="flex flex-col gap-3">
								{events?.filter(
									event => DateTime.fromMillis(Number(event.from)).diffNow().milliseconds > 0
								).length > 0 ? (
									events
										.filter(
											event => DateTime.fromMillis(Number(event.from)).diffNow().milliseconds > 0
										)
										.slice(0, 3)
										.map(event => (
											<div
												style={{ boxShadow: '0px 4px 24px 0px #CFD8DCBD' }}
												onClick={() => navigate('/events', { state: event._id })}
												className="rounded-lg cursor-pointer p-1 flex items-center gap-2 md:gap-3"
												key={event._id}>
												<time className="bg-[#CFD8DC80] rounded p-2 flex flex-col items-center gap-1">
													<p className="font-domine font-semibold text-primary text-xs md:text-sm">
														{DateTime.fromMillis(event.from).toFormat('dd')}
													</p>
													<p className="font-semibold text-[11px] text-center text-primary">
														{DateTime.fromMillis(event.from).toFormat('LLL,yyyy')}
													</p>
												</time>
												<div className="flex flex-col gap-1 md:gap-0.5">
													<ReactTooltip
														placement="bottom"
														style="dark"
														className="whitespace-nowrap text-white bg-[#323232] text-sm"
														content={event.title}>
														<h1 className="text-primary w-full line-clamp-1 max-md:text-xs font-semibold">
															{event.title}
														</h1>
													</ReactTooltip>
													<div className="flex items-center gap-2.5 text-[#4C6780]">
														<p className="text-[#757575] text-xs font-semibold">
															{event.addedBy.fname + ' ' + event.addedBy.lname}
														</p>
														<ClockIcon className="size-4" />
														<p className="text-[#757575] text-xs font-semibold">
															{DateTime.fromMillis(event.from).toFormat('hh:mm a')}
														</p>
													</div>
												</div>
											</div>
										))
								) : (
									<p className="text-tertiary text-sm">{t(tKey('labels.noUpcomingEvents'))}</p>
								)}
							</div>
						</div>
					</div>

					<div
						style={{ backgroundPositionY: '50px' }}
						className="md:basis-[25%] max-md:hidden bg-white bg-booking-overview bg-right-bottom bg-cover bg-no-repeat border border-[#CFD8DC] rounded-xl p-4 flex flex-col gap-4">
						<h1 className="font-domine font-bold text-primary text-sm">
							{t(tKey('titles.bookingsOverview'))}
						</h1>
						<div className="flex flex-col items-center gap-4">
							<PieChart width={162} height={162}>
								<Pie
									data={data}
									cx={81}
									cy={81}
									innerRadius={55}
									outerRadius={70}
									stroke="none"
									fill="#8884d8"
									cornerRadius={10}
									paddingAngle={5}
									dataKey="value">
									{data.map((entry, index) => (
										<Cell key={`cell-${index}`} fill={COLORS[index % COLORS?.length]} />
									))}
									<Label
										value={`${companyAppointments?.length + events?.length} ${t(
											tKey('labels.total')
										)}`}
										position="center"
										fill="#001E00"
										style={{ fontSize: '19px', fontWeight: 600 }}
									/>
								</Pie>
							</PieChart>
							<div className="flex items-center gap-[31px] justify-between">
								<div className="flex items-center gap-[9px]">
									<div className="bg-primary-light size-3 rounded-[1px]" />
									<div className="flex flex-col gap-1">
										<p className="text-primary italic font-bold text-sm">
											{companyAppointments?.length}
										</p>
										<p className="text-primary italic text-sm">{t(tKey('labels.appointments'))}</p>
									</div>
								</div>

								<div className="flex items-center gap-[9px]">
									<div className="bg-[#5555FB] size-3 rounded-[1px]" />
									<div className="flex flex-col gap-1">
										<p className="text-primary italic font-bold text-sm">{events?.length}</p>
										<p className="text-primary italic text-sm">{t(tKey('labels.events'))}</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</AppLayout>
	)
}
