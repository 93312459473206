import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline'
import { yupResolver } from '@hookform/resolvers/yup'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import clsx from 'clsx'
import * as yup from 'yup'

import { unwrapResult } from '@reduxjs/toolkit'
import { AppointoWhiteIcon, LoginBackgroundIcon } from 'assets/icons'
import { Spinner } from 'components/animations/spinner'
import { Alert } from 'components/app/alert'
import { Button } from 'components/app/button'
import { LanguageMenu } from 'components/app/menu'
import { Checkbox } from 'components/inputs/checkbox'
import { Input } from 'components/inputs/input'
import { PinInput } from 'components/inputs/pin-input'
import { useAppDispatch } from 'hooks'
import authService from 'services/auth-service'
import { login } from 'slices/auth'
import { getTKey } from 'utils/language'

enum AuthOptions {
	LOGIN = 'login',
	FORGOTPASSWORD = 'forgotPassword',
	RESETPASSWORD = 'resetPassword',
	OTP = 'otp'
}

export const Auth = () => {
	const { i18n } = useTranslation()
	const [searchParams, _setSearchParams] = useSearchParams()

	const [loginData, setLoginData] = useState<LoginForm>()

	const changeLanguage = (lng: string) => {
		i18n.changeLanguage(lng)
	}

	return (
		<div className="bg-background">
			<div className="relative min-h-screen flex items-center justify-center">
				<img
					src={AppointoWhiteIcon}
					className="md:mt-10 max-md:-translate-y-[165px] absolute md:-translate-y-64 md:-translate-x-52 z-10 w-64 h-64 max-md:w-56 max-md:h-56"
				/>
				<div className="absolute z-20 top-5 right-5 md:top-10 lg:right-10">
					<LanguageMenu
						value={i18n.language || window.localStorage.i18nextLng}
						onChange={changeLanguage}
						options={['en', 'de']}
					/>
				</div>
				<img
					src={LoginBackgroundIcon}
					className="absolute h-3/4 w-full object-cover object-bottom top-0"
				/>
				<div
					style={{ boxShadow: '6px 7px 24px 0px rgba(127, 154, 178, 0.22)' }}
					className="bg-white mt-44 max-md:inset-x-5 md:w-[750px] absolute z-10 px-5 py-8 md:px-[102px] md:py-14 rounded-xl">
					{(!searchParams.get('tab') || searchParams.get('tab') === AuthOptions.LOGIN) && (
						<Login setLoginData={setLoginData} />
					)}
					{searchParams.get('tab') === AuthOptions.OTP && (
						<Otp
							username={loginData?.username as string}
							password={loginData?.password as string}
						/>
					)}
					{searchParams.get('tab') === AuthOptions.FORGOTPASSWORD && <ForgotPassword />}
					{searchParams.get('tab') === AuthOptions.RESETPASSWORD && <ResetPassword />}
				</div>
			</div>
		</div>
	)
}

interface LoginProps {
	setLoginData: (data: LoginForm) => void
}

const Login = ({ setLoginData }: LoginProps) => {
	const { t } = useTranslation()
	const dispatch = useAppDispatch()
	const tKey = getTKey('auth.login')

	const [isPasswordVisible, setIsPasswordVisible] = useState(false)
	const [_searchParams, setSearchParams] = useSearchParams()
	const [isLoading, setIsLoading] = useState(false)

	const schema = yup.object<LoginForm>().shape({
		username: yup
			.string()
			.required(t(tKey('errors.email')))
			.email(t(tKey('errors.validEmail'))),
		password: yup
			.string()
			.required(t(tKey('errors.password')))
			.min(8, t(tKey('errors.minPassword')))
	})

	const {
		register,
		handleSubmit,
		formState: { errors }
	} = useForm<LoginForm>({
		resolver: yupResolver(schema as any),
		mode: 'all'
	})

	const onSubmit = (data: LoginForm) => {
		setIsLoading(true)
		authService
			.login(data.username, data.password)
			.then(() => {
				setLoginData(data)
				setSearchParams({ tab: AuthOptions.OTP }, { replace: true })
			})
			.catch(error => {
				if (error?.response?.data?.message) {
					return toast.error(error?.response?.data?.message)
				}
				toast.error(t(tKey('toast.invalidCredentials')))
			})
			.finally(() => setIsLoading(false))
	}

	return (
		<div>
			<h2 className="text-center font-domine text-[#123258] md:text-3xl text-[22px] font-bold leading-10 pb-2.5 max-md:text-center">
				{t(tKey('title'))}
			</h2>
			<p
				dangerouslySetInnerHTML={{ __html: t(tKey('welcome')) }}
				className="text-center max-md:px-5 text-tertiary leading-6 pb-4 2xl:pb-8 max-md:text-center"
			/>

			<form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-y-8 md:gap-y-10">
				<div className="flex flex-col gap-y-5 md:gap-y-6">
					<div className="flex flex-col gap-y-5 md:gap-y-8">
						<Input
							type="email"
							register={register}
							name="username"
							labelText={t(tKey('labels.email'))}
							errors={errors}
						/>

						<div className="relative z-0 flex items-center">
							<Input
								type={isPasswordVisible ? 'text' : 'password'}
								register={register}
								errors={errors}
								autoCapitalize="false"
								autoCorrect="off"
								autoComplete="new-password"
								name="password"
								labelText={t(tKey('labels.password'))}
							/>
							<div
								onClick={() => setIsPasswordVisible(!isPasswordVisible)}
								className={clsx('cursor-pointer absolute right-4', {
									'-translate-y-2.5': errors?.password
								})}>
								{isPasswordVisible ? (
									<EyeIcon className="w-6 h-6 stroke-primary" />
								) : (
									<EyeSlashIcon className="w-6 h-6 stroke-primary" />
								)}
							</div>
						</div>
					</div>
					<div className="flex items-center gap-x-1 justify-between">
						<div className="flex items-center justify-between gap-x-4 text-xs sm:text-sm">
							<div className="text-sm gap-x-1 flex items-center ">
								<Checkbox name="remember-me" register={register} error={errors} />
								<label className="flex whitespace-nowrap font-medium text-tertiary justify-right">
									{t(tKey('labels.rememberMe'))}
								</label>
							</div>
						</div>

						<span
							onClick={() => setSearchParams({ tab: AuthOptions.FORGOTPASSWORD })}
							className="text-primary-light cursor-pointer hover:underline text-xs sm:text-sm max-md:text-sm whitespace-nowrap">
							{t(tKey('labels.forgotPassword'))}
						</span>
					</div>
				</div>

				<div className="flex justify-center">
					<Button
						className="bg-primary w-[335px] text-white py-3 px-0 capitalize text-sm"
						type="submit"
						disabled={isLoading}>
						{isLoading ? (
							<div className="flex items-center justify-center gap-x-5">
								<Spinner />
								<span className="animate-pulse whitespace-nowrap">
									{t(tKey('buttons.pleaseWait'))}
								</span>
							</div>
						) : (
							<span>{t(tKey('buttons.signIn'))}</span>
						)}
					</Button>
				</div>
			</form>
		</div>
	)
}

interface OtpProps {
	username: string
	password: string
}

const Otp = ({ username, password }: OtpProps) => {
	const { t } = useTranslation()
	const dispatch = useAppDispatch()
	const tKey = getTKey('auth.otp')

	const [pin, setPin] = useState('')
	const [isLoading, setIsLoading] = useState(false)

	const onSubmit = (event: React.FormEvent) => {
		event.preventDefault()
		if (!pin || pin.length !== 6) return toast.error(t(tKey('toast.validOtp')))
		setIsLoading(true)
		dispatch(login({ username, otp: pin }))
			.then(res => {
				unwrapResult(res)
				toast.success(t(tKey('toast.loginSuccess')))
			})
			.catch(() => toast.error(t(tKey('toast.verificationFailed'))))
			.finally(() => setIsLoading(false))
	}

	const onResendOtp = () => {
		authService.login(username, password).then(() => toast.success(t(tKey('toast.resendOtp'))))
	}

	return (
		<div>
			<h2 className="text-center font-domine text-[#123258] md:text-3xl text-[22px] font-bold leading-10 pb-2.5 max-md:text-center">
				{t(tKey('title'))}
			</h2>
			<p className="text-center max-md:px-5 text-tertiary leading-6 pb-4 2xl:pb-8 max-md:text-center">
				{t(tKey('message'))} <span className="text-primary italic">{username}</span>
			</p>

			<form onSubmit={onSubmit} className="flex flex-col gap-y-8 md:gap-y-10">
				<div className="flex flex-col gap-y-5 md:gap-y-[52px]">
					<PinInput value={pin} valueLength={6} onChange={(value: string) => setPin(value)} />
					<p className="text-tertiary text-center">
						{t(tKey('linkText'))}{' '}
						<span
							onClick={onResendOtp}
							className="text-primary-light cursor-pointer font-semibold underline">
							{t(tKey('link'))}
						</span>
					</p>
				</div>

				<div className="flex justify-center">
					<Button
						className="bg-primary w-[335px] text-white py-3 px-0 capitalize text-sm"
						type="submit"
						disabled={isLoading}>
						{isLoading ? (
							<div className="flex items-center justify-center gap-x-5">
								<Spinner />
								<span className="animate-pulse whitespace-nowrap">
									{t(tKey('buttons.pleaseWait'))}
								</span>
							</div>
						) : (
							<span>{t(tKey('buttons.send'))}</span>
						)}
					</Button>
				</div>
			</form>
		</div>
	)
}

const ForgotPassword = () => {
	const { t } = useTranslation()
	const [_searchParams, setSearchParams] = useSearchParams()
	const tKey = getTKey('auth.forgotPassword')

	const [isLoading, setIsLoading] = useState(false)
	const [isVerificationSent, setIsVerificationSent] = useState(false)

	const schema = yup.object<{ email: string }>().shape({
		email: yup
			.string()
			.required(t(tKey('errors.email')))
			.email(t(tKey('errors.validEmail')))
	})

	const {
		register,
		handleSubmit,
		formState: { errors }
	} = useForm<{ email: string }>({
		resolver: yupResolver(schema as any),
		mode: 'all'
	})

	const onSubmit = (data: { email: string }) => {
		setIsLoading(true)
		setIsVerificationSent(false)
		authService
			.forgotPassword(data.email)
			.then(() => setIsVerificationSent(true))
			.catch(() => toast.error(t(tKey('toast.notFound'))))
			.finally(() => setIsLoading(false))
	}

	return (
		<div className="relative">
			{isVerificationSent && (
				<div className="flex justify-center">
					<Alert
						content={t(tKey('alert'))}
						className="absolute -translate-y-16 md:-translate-y-[88px] "
					/>
				</div>
			)}

			<h2 className="text-center font-domine text-[#123258] md:text-3xl text-[22px] font-bold leading-10 pb-2.5 max-md:text-center">
				{t(tKey('title'))}
			</h2>
			<p className="text-center max-md:px-5 text-tertiary leading-6 pb-4 2xl:pb-8 max-md:text-center">
				{t(tKey('message'))}
			</p>

			<form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-y-8 md:gap-y-10">
				<Input
					type="email"
					register={register}
					name="email"
					labelText={t(tKey('labels.email'))}
					errors={errors}
				/>

				<div className="flex justify-center">
					<Button
						className="bg-primary w-[335px] text-white py-3 px-0 capitalize text-sm"
						type="submit"
						disabled={isLoading}>
						{isLoading ? (
							<div className="flex items-center justify-center gap-x-5">
								<Spinner />
								<span className="animate-pulse whitespace-nowrap">
									{t(tKey('buttons.pleaseWait'))}
								</span>
							</div>
						) : (
							<span>
								{isVerificationSent ? t(tKey('buttons.resend')) : t(tKey('buttons.send'))}
							</span>
						)}
					</Button>
				</div>
				<p className="text-tertiary text-center">
					{t(tKey('linkText'))}{' '}
					<span
						onClick={() => setSearchParams({ tab: AuthOptions.LOGIN }, { replace: true })}
						className="text-primary font-semibold underline cursor-pointer">
						{t(tKey('link'))}
					</span>
				</p>
			</form>
		</div>
	)
}

const ResetPassword = () => {
	const { t } = useTranslation()
	const [searchParams, setSearchParams] = useSearchParams()
	const tKey = getTKey('auth.resetPassword')

	const email = searchParams.get('email') as string
	const code = searchParams.get('code') as string

	const [isLoading, setIsLoading] = useState(false)
	const [isPasswordReset, setIsPasswordReset] = useState(false)
	const [isPasswordVisible, setIsPasswordVisible] = useState({
		password: false,
		confirmPassword: false
	})

	const schema = yup.object<{ password: string; confirmPassword: string }>().shape({
		password: yup
			.string()
			.required(t(tKey('errors.password')))
			.matches(
				/^(?=.*[A-Z])(?=.*[0-9!@#\$%\^\&*\)\(+=._-]).{8,}$/,
				t('employees.create.errors.validPassword')
			)
			.min(8, t(tKey('errors.minPassword'))),
		confirmPassword: yup
			.string()
			.required(t(tKey('errors.confirmPassword')))
			.oneOf([yup.ref('password'), ''], t(tKey('errors.matchPassword')))
	})

	const {
		register,
		handleSubmit,
		formState: { errors }
	} = useForm<{ password: string; confirmPassword: string }>({
		resolver: yupResolver(schema as any),
		mode: 'all'
	})

	const onSubmit = (data: { password: string; confirmPassword: string }) => {
		setIsLoading(true)
		authService
			.resetPassword(email, data.password, code)
			.then(() => setIsPasswordReset(true))
			.catch(() => toast.error(t(tKey('toast.resetError'))))
			.finally(() => setIsLoading(false))
	}

	return (
		<div className="relative">
			{isPasswordReset && (
				<div className="flex justify-center">
					<Alert
						content={t(tKey('alert'))}
						className="absolute -translate-y-16 md:-translate-y-[88px] "
					/>
				</div>
			)}

			<h2 className="text-center font-domine text-[#123258] md:text-3xl text-[22px] font-bold leading-10 pb-2.5 max-md:text-center">
				{t(tKey('title'))}
			</h2>
			<p className="text-center max-md:px-5 text-tertiary leading-6 pb-4 2xl:pb-8 max-md:text-center">
				{t(tKey('message'))}
			</p>

			<form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-y-8 md:gap-y-10">
				<div className="flex flex-col gap-y-5">
					<div>
						<div className="relative z-0 flex items-center">
							<Input
								type={isPasswordVisible.password ? 'text' : 'password'}
								register={register}
								autoCapitalize="false"
								autoCorrect="off"
								autoComplete="new-password"
								name="password"
								labelText={t(tKey('labels.password'))}
							/>
							<div
								onClick={() =>
									setIsPasswordVisible(prev => ({ ...prev, password: !prev.password }))
								}
								className={clsx('cursor-pointer absolute right-4', {
									'-translate-y-2.5': errors?.password
								})}>
								{isPasswordVisible.password ? (
									<EyeIcon className="w-6 h-6 stroke-primary" />
								) : (
									<EyeSlashIcon className="w-6 h-6 stroke-primary" />
								)}
							</div>
						</div>
						{errors.password && (
							<p className="text-xs text-red-500 mt-1">{errors.password.message}</p>
						)}
					</div>
					<div className="relative z-0 flex items-center">
						<Input
							type={isPasswordVisible.confirmPassword ? 'text' : 'password'}
							register={register}
							errors={errors}
							autoCapitalize="false"
							autoCorrect="off"
							autoComplete="new-password"
							name="confirmPassword"
							labelText={t(tKey('labels.confirmPassword'))}
						/>
						<div
							onClick={() =>
								setIsPasswordVisible(prev => ({ ...prev, confirmPassword: !prev.confirmPassword }))
							}
							className={clsx('cursor-pointer absolute right-4', {
								'-translate-y-2.5': errors?.confirmPassword
							})}>
							{isPasswordVisible.confirmPassword ? (
								<EyeIcon className="w-6 h-6 stroke-primary" />
							) : (
								<EyeSlashIcon className="w-6 h-6 stroke-primary" />
							)}
						</div>
					</div>
				</div>

				<div className="flex justify-center">
					<Button
						className={clsx(
							'w-[335px] text-white py-3 px-0 capitalize text-sm',
							isPasswordReset ? 'bg-[#b1bbc8]' : 'bg-primary'
						)}
						type="submit"
						disabled={isLoading || isPasswordReset}>
						{isLoading ? (
							<div className="flex items-center justify-center gap-x-5">
								<Spinner />
								<span className="animate-pulse whitespace-nowrap">
									{t(tKey('buttons.pleaseWait'))}
								</span>
							</div>
						) : (
							<span>{t(tKey('buttons.resetPassword'))}</span>
						)}
					</Button>
				</div>
				<p className="text-tertiary text-center">
					{t(tKey('linkText'))}{' '}
					<span
						onClick={() => setSearchParams({ tab: AuthOptions.LOGIN }, { replace: true })}
						className="text-primary font-semibold underline cursor-pointer">
						{t(tKey('link'))}
					</span>
				</p>
			</form>
		</div>
	)
}
