import activeAppointmentIcon from 'assets/svgs/active-appointments.svg'
import activeCalendarIcon from 'assets/svgs/active-calendar.svg'
import activeClientIcon from 'assets/svgs/active-clients.svg'
import activeDashboardIcon from 'assets/svgs/active-dashboard.svg'
import activeLicensesIcon from 'assets/svgs/active-licenses.svg'
import activeMessageIcon from 'assets/svgs/active-message-circle.svg'
import activeNewspaperIcon from 'assets/svgs/active-newspaper.svg'
import activeServicesIcon from 'assets/svgs/active-services.svg'
import activeSettingsIcon from 'assets/svgs/active-settings.svg'
import activeSubCompanyIcon from 'assets/svgs/active-subcompany.svg'
import activeTaskManagerIcon from 'assets/svgs/active-task-manager.svg'
import appartmentIcon from 'assets/svgs/apartment.svg'
import appointmentIcon from 'assets/svgs/appointments.svg'
import appointoGreen from 'assets/svgs/appointo-green.svg'
import appointoWhite from 'assets/svgs/appointo-white.svg'
import calendarIcon from 'assets/svgs/calendar.svg'
import cameraIcon from 'assets/svgs/camera.svg'
import completeIcon from 'assets/svgs/check-circle.svg'
import checkedCircleIcon from 'assets/svgs/check.svg'
import circleIcon from 'assets/svgs/circle.svg'
import clientIcon from 'assets/svgs/clients.svg'
import copyIcon from 'assets/svgs/copy.svg'
import currentCircleIcon from 'assets/svgs/current.svg'
import dashboardIcon from 'assets/svgs/dashboard.svg'
import dollarIcon from 'assets/svgs/dollar.svg'
import englishFlagIcon from 'assets/svgs/english-flag.svg'
import eventCalendarIcon from 'assets/svgs/event-calendar.svg'
import expandIcon from 'assets/svgs/expand.svg'
import germanFlagIcon from 'assets/svgs/german-flag.svg'
import greenFlagIcon from 'assets/svgs/green-flag.svg'
import licensesIcon from 'assets/svgs/licenses.svg'
import messageIcon from 'assets/svgs/message-circle.svg'
import newsPaperIcon from 'assets/svgs/newspaper.svg'
import pendingIcon from 'assets/svgs/pending.svg'
import redFlagIcon from 'assets/svgs/red-flag.svg'
import sendButton from 'assets/svgs/send-button.svg'
import servicesIcon from 'assets/svgs/services.svg'
import settingsIcon from 'assets/svgs/settings.svg'
import smallGreenFlagIcon from 'assets/svgs/small-green-flag.svg'
import smallRedFlagIcon from 'assets/svgs/small-red-flag.svg'
import smallWhiteFlagIcon from 'assets/svgs/small-white-flag.svg'
import smallYellowFlagIcon from 'assets/svgs/small-yellow-flag.svg'
import stripeIcon from 'assets/svgs/stripe.svg'
import subCompanyIcon from 'assets/svgs/subcompany.svg'
import taskManagerIcon from 'assets/svgs/task-manager.svg'
import cancelledIcon from 'assets/svgs/warning.svg'
import whiteFlagIcon from 'assets/svgs/white-flag.svg'
import yellowFlagIcon from 'assets/svgs/yellow-flag.svg'

import accountIcon from 'assets/pngs/account.png'
import appointmentBackgroundIcon from 'assets/pngs/appointment-background.png'
import appointmentDetails from 'assets/pngs/appointment-details.png'
import blocks from 'assets/pngs/blocks.png'
import checkmarkIcon from 'assets/pngs/checkmark.png'
import dangerIcon from 'assets/pngs/danger.png'
import deleteIcon from 'assets/pngs/delete.png'
import loginbackgroundIcon from 'assets/pngs/login.png'
import noInboxIcon from 'assets/pngs/no-inbox.png'
import noMessagesIcon from 'assets/pngs/no-messages.png'
import profilePhotoIcon from 'assets/pngs/profile-photo.png'
import transparentIcon from 'assets/pngs/transparent-icon.png'
import warningIcon from 'assets/pngs/warning.png'

export const ActiveAppointmentIcon = activeAppointmentIcon
export const ActiveCalendarIcon = activeCalendarIcon
export const ActiveClientIcon = activeClientIcon
export const ActiveDashboardIcon = activeDashboardIcon
export const ActiveServicesIcon = activeServicesIcon
export const ActiveSettingsIcon = activeSettingsIcon
export const ActiveMessageIcon = activeMessageIcon
export const ActiveNewspaperIcon = activeNewspaperIcon
export const ActiveSubCompanyIcon = activeSubCompanyIcon
export const ActiveTaskManagerIcon = activeTaskManagerIcon
export const AppointmentSidebarIcon = appointmentIcon
export const AppartmentIcon = appartmentIcon
export const AppointoGreenIcon = appointoGreen
export const AppointoWhiteIcon = appointoWhite
export const CameraIcon = cameraIcon
export const CalendarSidebarIcon = calendarIcon
export const CheckedCircleIcon = checkedCircleIcon
export const CircleIcon = circleIcon
export const CompleteIcon = completeIcon
export const CopyIcon = copyIcon
export const CurrentCircleIcon = currentCircleIcon
export const ClientSidebarIcon = clientIcon
export const DashboardSidebarIcon = dashboardIcon
export const DollarIcon = dollarIcon
export const EnglishFlagIcon = englishFlagIcon
export const EventCalendarIcon = eventCalendarIcon
export const ExpandIcon = expandIcon
export const GermanFlagIcon = germanFlagIcon
export const GreenFlagIcon = greenFlagIcon
export const LicensesIcon = licensesIcon
export const ActiveLicensesIcon = activeLicensesIcon
export const MessageIcon = messageIcon
export const NewspaperIcon = newsPaperIcon
export const PendingIcon = pendingIcon
export const RedFlagIcon = redFlagIcon
export const ServicesSidebarIcon = servicesIcon
export const SettingsSidebarIcon = settingsIcon
export const SmallGreenFlagIcon = smallGreenFlagIcon
export const SmallRedFlagIcon = smallRedFlagIcon
export const SmallYellowFlagIcon = smallYellowFlagIcon
export const SmallWhiteFlagIcon = smallWhiteFlagIcon
export const SubCompanyIcon = subCompanyIcon
export const StripeIcon = stripeIcon
export const TaskManagerIcon = taskManagerIcon
export const CheckmarkIcon = checkmarkIcon
export const YellowFlagIcon = yellowFlagIcon
export const DeleteIcon = deleteIcon
export const CancelledIcon = cancelledIcon
export const WhiteFlagIcon = whiteFlagIcon

export const AppointmentDetailsIcon = appointmentDetails
export const AccountIcon = accountIcon
export const AppointmentBackgroundIcon = appointmentBackgroundIcon
export const BlockIcon = blocks
export const DangerIcon = dangerIcon
export const LoginBackgroundIcon = loginbackgroundIcon
export const NoInboxIcon = noInboxIcon
export const ProfilePhotoIcon = profilePhotoIcon
export const NoMessagesIcon = noMessagesIcon
export const SendButtonIcon = sendButton
export const TransparentIcon = transparentIcon
export const WarningIcon = warningIcon
