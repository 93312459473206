import { InputHTMLAttributes } from 'react'
import { FieldError, FieldErrors, FieldValues } from 'react-hook-form'

import { toast } from 'react-toastify'
import { t } from 'utils/language'
import { containsOnlyDigits } from 'utils/string'

type InputProps = InputHTMLAttributes<HTMLInputElement> & {
	onNumberChange: (num: number) => void
	value?: number
	step?: number
	minimumValue?: number
	maxLength?: number
	name?: string
	allowDecimal?: boolean
	error?: FieldErrors<FieldValues>
	controllerError?: FieldError | undefined
	maxValue?: number
}

export const CounterInput = ({
	onNumberChange,
	onChange,
	step = 1,
	minimumValue = 0,
	maxLength,
	maxValue,
	name,
	controllerError,
	value,
	...props
}: InputProps) => {
	const errorText = controllerError?.message as string

	const input = value?.toString() ?? ''
	// const [input, setInput] = useState<string>(value?.toString() ?? '')

	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = event.target

		// Check if input value contains only digits
		if (
			value === '' ||
			(containsOnlyDigits(value) &&
				(maxLength ? value.length <= maxLength && Number(value) >= minimumValue : true))
		) {
			onChange?.(event)
		}

		if (maxValue && maxValue < parseInt(value)) {
			onNumberChange(maxValue)
		}
	}

	return (
		<>
			<div className="flex justify-center w-full">
				<div className="relative py-2 px-4 shadow-md flex justify-center border border-gray-200 flex-row rounded-lg bg-white space-x-2">
					<div className="inline-flex items-center justify-center h-full rounded-l px-2">
						<button
							type="button"
							onClick={event => {
								event.preventDefault()
								const stepValue = Number(((value ?? 0) - step).toFixed(1))
								const minVal = Number(minimumValue)
								if (stepValue < minVal) {
									return toast.error(t('inputs.errors.lessThan') + ' ' + minVal)
								}
								onNumberChange(stepValue)
							}}
							className="inline-flex justify-center items-center cursor-pointer h-8 w-8 rounded-full text-white bg-[#61BC5F] outline-none hover:text-gray-700">
							<span className="inline-block hover:text-white text-2xl font-serif font-medium">
								−
							</span>
						</button>
					</div>
					<input
						type="number"
						value={input}
						inputMode="numeric"
						name={name}
						onChange={handleInputChange}
						className="text-md md:text-base cursor-default text-primary flex w-20 items-center rounded-lg text-center font-semibold outline-none bg-white hover:text-primary focus:text-primary focus:ring-0 focus:border-secondary focus:outline-none appearance-none"
						{...props}
					/>
					<div className="inline-flex items-center justify-center h-full rounded-r px-2">
						<button
							type="button"
							onClick={event => {
								event.preventDefault()
								if (maxValue) {
									const stepValue = Number(((value ?? 0) + step).toFixed(1))
									const minVal = Number(maxValue)
									if (stepValue > minVal) {
										return toast.error(t('inputs.errors.greaterThan') + ' ' + maxValue)
									}
									if ((value ?? 0) < maxValue) {
										onNumberChange(Number(((value ?? 0) + step).toFixed(1)))
									}
								} else {
									onNumberChange(Number(((Number(value) || 0) + step).toFixed(1)))
								}
							}}
							className="flex justify-center items-center cursor-pointer h-8 w-8 rounded-full text-white bg-[#61BC5F] outline-none hover:text-gray-700">
							<span className="inline-block hover:text-white text-2xl font-serif font-medium">
								+
							</span>
						</button>
					</div>
				</div>
			</div>
			{errorText && <p className="text-xs text-center text-red-500 mt-1">{errorText}</p>}
		</>
	)
}
