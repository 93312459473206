import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline'
import { useEffect, useState } from 'react'
import { UseFormSetValue } from 'react-hook-form'

import clsx from 'clsx'
import { DateTime } from 'luxon'
import appointmentService from 'services/appointment-service'
import { getAppLang } from 'utils/language'

interface TimePickerProps {
	timeSlots: { [day: number]: string[] }
	todayTimeSlots: { [day: number]: string[] }
	setSelectedTimeSlot?: (value: string) => void
	setSelectedDate?: (date: string) => void
	setValue?: UseFormSetValue<any>
	selectedTimeSlot: string
	duration: string
	selectedDate: Date
	companyId: string
	employeeId?: string
}

export const TimePicker = ({
	timeSlots,
	todayTimeSlots,
	setValue,
	duration,
	selectedTimeSlot,
	setSelectedTimeSlot,
	setSelectedDate,
	companyId,
	employeeId,
	selectedDate
}: TimePickerProps) => {
	const today = DateTime.now()
	const [currentDate, setCurrentDate] = useState(today)
	const [appointments, setAppointments] = useState<{ from: number; to: number }[]>([])
	const [bookedSlots, setBookedSlots] = useState<{ [day: number]: string[] }>({})

	const prevDate = () => {
		if (currentDate.hasSame(DateTime.now(), 'day')) return
		setCurrentDate(currentDate.minus({ days: 1 }))
	}

	const nextDate = () => {
		setCurrentDate(currentDate.plus({ days: 1 }))
	}

	useEffect(() => {
		appointmentService
			.getAppointmentSlots(
				companyId,
				currentDate.startOf('day').toMillis(),
				currentDate.endOf('day').plus({ days: 2 }).toMillis(),
				employeeId
			)
			.then(res => setAppointments(res))
	}, [currentDate, employeeId])

	useEffect(() => {
		if (appointments && appointments.length > 0) {
			const time = appointments.reduce(
				(acc, appointment) => {
					if (!acc[DateTime.fromMillis(appointment.from).day]) {
						acc[DateTime.fromMillis(appointment.from).day] = []
					}
					acc[DateTime.fromMillis(appointment.from).day].push(
						`${DateTime.fromMillis(appointment.from).toFormat('HH:mm')} - ${DateTime.fromMillis(
							appointment.to
						).toFormat('HH:mm')}`
					)
					return acc
				},
				{} as { [date: number]: string[] }
			)
			setBookedSlots(time)
			setValue?.('timeslot', undefined)
		} else {
			setValue?.('timeslot', undefined)
			setBookedSlots({})
		}
	}, [appointments])

	const datesToShow = [currentDate, currentDate.plus({ days: 1 }), currentDate.plus({ days: 2 })]
	const appLanguage = getAppLang()

	const formatDate = (date: DateTime) => {
		return {
			day: date.setLocale(appLanguage).toFormat('EEE'),
			date: date.toFormat('dd'),
			year: date.setLocale(appLanguage).toFormat('LLL')
		}
	}

	return (
		<div className="grow flex justify-around overflow-y-auto overflow-x-hidden">
			<ChevronLeftIcon
				onClick={prevDate}
				className="sticky stroke-primary top-2 h-5 w-5 mt-2 shrink-0 cursor-pointer"
			/>
			{datesToShow.map((date, index) => {
				return (
					<div key={index} className="flex flex-col gap-y-2">
						<div className="flex flex-col w-[85px] sticky top-0 bg-white pb-2 items-center text-sm text-primary">
							<span>{formatDate(date).day}</span>
							<span>
								{formatDate(date).date}-{formatDate(date).year}
							</span>
						</div>
						<div className="flex gap-2.5 flex-col h-64">
							{(date.hasSame(DateTime.now(), 'day') ? todayTimeSlots : timeSlots)?.[
								date.weekday === 7 ? 0 : date.weekday
							]
								?.filter(slot => {
									const bookedSlot = bookedSlots[date.day] || []

									const dateTimeStartSlot = DateTime.fromFormat(slot, 'HH:mm')
									const dateTimeEndSlot = DateTime.fromFormat(slot, 'HH:mm').plus({
										minutes: Number(duration)
									})

									if (bookedSlot.length > 0) {
										for (let i = 0; i < bookedSlot.length; i++) {
											const [bookedSlotStart, bookedSlotEnd] = bookedSlot[i]
												.split('-')
												.map(time => time.trim())

											const bookedStart = DateTime.fromFormat(bookedSlotStart, 'HH:mm')
											const bookedEnd = DateTime.fromFormat(bookedSlotEnd, 'HH:mm')

											if (
												(dateTimeStartSlot >= bookedStart && dateTimeStartSlot < bookedEnd) ||
												(dateTimeEndSlot > bookedStart && dateTimeEndSlot <= bookedEnd) ||
												(bookedStart >= dateTimeStartSlot && bookedStart < dateTimeEndSlot) ||
												(bookedEnd > dateTimeStartSlot && bookedEnd <= dateTimeEndSlot)
											) {
												return false
											}
										}
									}
									return true
								})
								.map(slots => (
									<div
										key={slots}
										onClick={() => {
											setValue?.('timeslot', slots, { shouldValidate: true })
											setSelectedTimeSlot?.(slots)
											setSelectedDate?.(date.toISODate())
											setValue?.('appointmentDate', date.toISODate())
										}}
										className={clsx(
											'flex justify-center items-center cursor-pointer py-0.5 h-[30px] px-2.5 border rounded text-sm',
											slots === selectedTimeSlot && date.toISODate() === (selectedDate as any)
												? 'text-white bg-[#61BC5F] border-transparent'
												: 'text-[#4A5F73] border-[#D3E3F1]'
										)}>
										{slots}
									</div>
								))}
						</div>
					</div>
				)
			})}
			<ChevronRightIcon
				onClick={nextDate}
				className="h-5 w-5 shrink-0 sticky top-2 mt-2 cursor-pointer stroke-primary"
			/>
		</div>
	)
}
