import React from 'react'

import { AppHeader } from 'components/app/header'
import { Sidebar } from 'components/app/sidebar'
import { useDocumentTitle } from 'hooks/useDocumentTitle'

interface AppLayoutProps {
	children: React.ReactNode
	title: string
	docTitle?: string
	isHeaderVisible?: boolean
	isSidebarVisible?: boolean
}

export const AppLayout: React.FC<AppLayoutProps> = ({
	isSidebarVisible = true,
	isHeaderVisible = true,
	title,
	docTitle,
	children
}) => {
	const docTitleText = docTitle ? `${docTitle} | Appointo-CRM` : 'Appointo CRM Booking Tool'
	useDocumentTitle(docTitleText)

	return (
		<div className="relative">
			{isSidebarVisible ? (
				isHeaderVisible ? (
					<Sidebar>
						<AppHeader title={title} />
						{children}
					</Sidebar>
				) : (
					<Sidebar>{children}</Sidebar>
				)
			) : (
				<>{children}</>
			)}
		</div>
	)
}
